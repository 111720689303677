.adv-cal-cap {
  &-container {
    min-height: 800px;
    background-image: url('/media/export/cms/advent_calendar/background-mobile-v1.jpg');
    @include breakpoint($bp--large-up) {
      background-image: url('/media/export/cms/advent_calendar/background-desktop-v1.jpg');
    }
    .cards {
      position: relative;
      @supports (display: flex) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: center;
        justify-content: center;
      }
      @supports not (display: flex) {
        display: inline-block;
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
        background-repeat: no-repeat;
        z-index: 0;
      }
      &:before {
        background-image: url('/media/export/cms/advent_calendar/bow-side-left-pink.png');
        left: -50px;
        top: 5%;
        width: 99px;
        height: 96px;
      }
      &:after {
        background-image: url('/media/export/cms/advent_calendar/bow-side-right-silver.png');
        right: -80px;
        top: 71%;
        width: 130px;
        height: 138px;
      }
      @include breakpoint($bp--large-up) {
        &:before,
        &:after {
          content: none;
        }
      }
      #advent_cal_1,
      #advent_cal_8,
      #advent_cal_12 {
        -webkit-flex: 1 0 #{$advent-calendar-card-width-mobile};
        flex: 1 0 #{$advent-calendar-card-width-mobile};
        max-width: #{$advent-calendar-card-width-mobile};
        @include breakpoint($bp--xxsmall-up) {
          -webkit-flex: 1 0 calc(#{$advent-calendar-card-width-mobile} * 2);
          flex: 1 0 calc(#{$advent-calendar-card-width-mobile} * 2);
          max-width: calc(#{$advent-calendar-card-width-mobile} * 2 + 2 * #{$advent-calendar-card-margin});
        }
        @include breakpoint($bp--large-up) {
          -webkit-flex: 1 0 calc(#{$advent-calendar-card-width-desktop} * 2);
          flex: 1 0 calc(#{$advent-calendar-card-width-desktop} * 2);
          max-width: calc(#{$advent-calendar-card-width-desktop} * 2 + 2 * #{$advent-calendar-card-margin});
          height: $advent-calendar-card-height-desktop;
        }
      }
      .card {
        background-color: $color--black;
        margin: $advent-calendar-card-margin;
        -webkit-flex: 1 0 $advent-calendar-card-width-mobile;
        flex: 1 0 $advent-calendar-card-width-mobile;
        max-width: $advent-calendar-card-width-mobile;
        height: $advent-calendar-card-height-mobile;
        @include breakpoint($bp--large-up) {
          -webkit-flex: 1 0 $advent-calendar-card-width-desktop;
          flex: 1 0 $advent-calendar-card-width-desktop;
          max-width: $advent-calendar-card-width-desktop;
          height: $advent-calendar-card-height-desktop;
        }
        a {
          color: $color--black;
        }
        &:not(.tout) {
          z-index: map-get($advent-calendar-z-index, card);
          &:before {
            content: none;
            color: $color--black;
            font-size: 22px;
            top: 0;
          }
          &:after {
            font-size: 110px;
            top: 50%;
            transform: translateY(-50%);
            @include breakpoint($bp--large-up) {
              font-size: 150px;
            }
          }
        }
        .card-content {
          background-color: transparent !important; /* This is needed to override CMS element inline aplied styling */
          .offer-img-bkg {
            opacity: 0;
            position: relative;
            z-index: 2;
            background-color: $color--white;
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            img {
              min-height: 100px;
              @include breakpoint($bp--large-up) {
                min-height: 228px;
              }
            }
          }
        }
        &.expired {
          background-color: rgba(0, 0, 0, 0.55);
          opacity: 1;
          &:before,
          &:after {
            color: rgba(138, 138, 138, 0.55);
          }
          &:hover {
            cursor: default;
            box-shadow: none;
            .card-content {
              .offer-img-bkg {
                -webkit-animation: advent-calendar-fadeinout 1s linear forwards;
                animation: advent-calendar-fadeinout 1s linear forwards;
              }
            }
          }
        }
        &.active {
          -webkit-animation: advent-calendar-glowing 3s infinite;
          animation: advent-calendar-glowing 3s infinite;
          border: 0 none;
          &:hover {
            animation: none;
            cursor: pointer;
            .card-content {
              .offer-img-bkg {
                -webkit-animation: advent-calendar-fadein 0.5s linear forwards;
                animation: advent-calendar-fadein 0.5s linear forwards;
              }
            }
          }
        }
        &.future {
          &:hover {
            cursor: default;
            .card-content {
              .show-for-future {
                cursor: pointer;
                -webkit-animation: advent-calendar-fadein 0.5s linear forwards;
                animation: advent-calendar-fadein 0.5s linear forwards;
              }
            }
          }
        }
        &:nth-child(1),
        &:nth-child(12),
        &:nth-child(19) {
          -webkit-flex: 1 0 calc(#{$advent-calendar-card-width-mobile} * 2);
          flex: 1 0 calc(#{$advent-calendar-card-width-mobile} * 2);
          max-width: calc(#{$advent-calendar-card-width-mobile} * 2 + 2 * #{$advent-calendar-card-margin});
          @include breakpoint($bp--large-up) {
            -webkit-flex: 1 0 calc(#{$advent-calendar-card-width-desktop} * 2);
            flex: 1 0 calc(#{$advent-calendar-card-width-desktop} * 2);
            max-width: calc(#{$advent-calendar-card-width-desktop} * 2 + 2 * #{$advent-calendar-card-margin});
            height: $advent-calendar-card-height-desktop;
          }
        }
      }
    }
    .adv-tc {
      margin: 50px 0;
      text-align: center;
      color: $color--white;
    }
  }
  &-title {
    position: relative;
    margin: 30px 0 0;
    padding: 0 40px;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 10%;
      display: block;
      background-repeat: no-repeat;
      z-index: 0;
    }
    &:before {
      background-image: url('/media/export/cms/advent_calendar/bow-mobile-title-left.png');
      left: -100px;
      width: 155px;
      height: 156px;
    }
    &:after {
      background-image: url('/media/export/cms/advent_calendar/bow-mobile-title-right.png');
      right: -80px;
      width: 112px;
      height: 119px;
      top: 30%;
    }
    @include breakpoint($bp--xxsmall-up) {
      &:before {
        left: -80px;
      }
      &:after {
        right: -60px;
      }
    }
    @include breakpoint($bp--large-up) {
      padding: 0;
      &:before,
      &:after {
        content: none;
      }
    }
    h2 {
      position: relative;
      max-width: 700px;
      width: 100%;
      height: 180px;
      margin: 0 auto 10px;
      font-size: 0;
      line-height: 0;
      color: transparent;
      z-index: 1;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: calc(50% + 20px);
        transform: translateX(-50%);
        display: block;
        max-width: 700px;
        width: 100%;
        height: inherit;
        background-image: url('/media/export/cms/advent_calendar/logo-desktop@2x.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center top;
      }
      &::after {
        content: '';
        position: absolute;
        top: calc(50% - 80px);
        left: calc(50% - 110px);
        display: block;
        width: 100px;
        height: 100px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        background-image: url($advent-calendar-glitter-image);
        overflow: hidden;
        z-index: 2;
        color: transparent;
        -moz-opacity: 0;
        opacity: 0;
        -webkit-animation: advent-calendar-glitter 4s linear 0s infinite normal;
        -moz-animation: advent-calendar-glitter 4s linear 0s infinite normal;
        -ms-animation: advent-calendar-glitter 4s linear 0s infinite normal;
        -o-animation: advent-calendar-glitter 4s linear 0s infinite normal;
        animation: advent-calendar-glitter 4s linear 0s infinite normal;
      }
      @include breakpoint($bp--xxsmall-up) {
        &::after {
          top: calc(50% - 65px);
          left: calc(50% - 135px);
        }
      }
      @include breakpoint($bp--large-up) {
        height: 334px;
        background-size: 584px 334px;
        &::after {
          top: calc(50% - 70px);
          left: calc(50% - 230px);
        }
      }
    }
    p {
      width: auto;
      max-width: 960px;
      padding: 0;
      font-family: $ano-bold_regular-font;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 1.5px;
      margin-bottom: 5px;
      text-transform: uppercase;
      @include breakpoint($bp--large-up) {
        font-size: 23px;
        line-height: 28px;
        letter-spacing: 3px;
        margin-bottom: 85px;
      }
    }
  }
  &-popup {
    position: absolute;
    z-index: map-get($advent-calendar-z-index, modal);
    background-color: $color--black--opacity;
    .popup-content-wrapper {
      top: 50%;
      max-width: none;
      background: $color--white;
      box-shadow: 0 0 40px 0 $color--white;
      @include breakpoint($bp--large-up) {
        max-width: 524px;
      }
    }
    .popup-content {
      padding: 190px 20px 20px;
      color: $color--black;
      background-image: url('/media/export/cms/advent_calendar/logo-mobile.png');
      background-repeat: no-repeat;
      background-position: calc(50% + 10px) 20px;
      background-size: 290px 166px;
      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
        z-index: map-get($advent-calendar-z-index, modal-before);
        background-repeat: no-repeat;
        background-size: 100%;
      }
      &::before {
        top: -24px;
        left: -78px;
        width: 119px;
        height: 242px;
        background-image: url('/media/export/cms/advent_calendar/bows-mobile-left-top.png');
        @include breakpoint($bp--xxsmall-up) {
          left: -68px;
        }
        @include breakpoint($bp--large-up) {
          top: -55px;
          left: -65px;
          width: 156px;
          height: 217px;
          background-image: url('/media/export/cms/advent_calendar/bows-desktop-left-top.png');
        }
      }
      &::after {
        right: -90px;
        bottom: 20px;
        width: 131px;
        height: 256px;
        background-image: url('/media/export/cms/advent_calendar/bows-mobile-right-bottom.png');
        @include breakpoint($bp--xxsmall-up) {
          right: -70px;
        }
        @include breakpoint($bp--large-up) {
          width: 130px;
          height: 247px;
          right: -35px;
          bottom: -45px;
          background-image: url('/media/export/cms/advent_calendar/bows-desktop-right-bottom.png');
        }
      }
      @include breakpoint($bp--large-up) {
        padding: 190px 40px 20px;
        background-image: url('/media/export/cms/advent_calendar/logo-desktop.png');
        background-position: calc(50% + 5px) 10px;
        background-size: 292px 167px;
      }
      .offer-details {
        position: relative;
        padding-top: 40px;
        margin-bottom: 20px;
        &::before {
          content: 'Your treat is...';
          position: absolute;
          left: 50%;
          top: 0;
          display: inline-block;
          width: 100%;
          font-family: $ano-bold_regular-font;
          font-size: 32px;
          line-height: 32px;
          text-transform: uppercase;
          transform: translateX(-50%);
        }
        .offer-img {
          display: block;
          visibility: visible;
          margin-bottom: 17px;
          img {
            height: 160px;
          }
        }
        @include breakpoint($bp--large-up) {
          padding-top: 50px;
          &::before {
            font-size: 40px;
            line-height: 40px;
          }
        }
      }
      h3 {
        display: none;
      }
      p {
        text-align: center;
        font-family: $roboto-mono_regular-font;
        letter-spacing: 1.5px;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 20px;
        padding: 0 12%;
        text-transform: uppercase;
      }
      .btn-primary {
        display: inline-block;
        background-color: $color--black;
        color: $color--white;
        border: none;
        font-family: $ano-bold_regular-font;
        font-size: 25px;
        line-height: 25px;
        padding: 12px 30px;
      }
      .popup-tc {
        font-size: 10px;
        line-height: 12px;
      }
      .popup-close {
        top: 21px;
        #{$rdirection}: 26px;
        display: inline-block;
        width: 30px;
        height: 30px;
        padding: 2px;
        transition: ease 0.25s all;
        transform: translate(50%, -50%);
        border-radius: 0;
        border: 0 none;
        background: transparent;
        text-align: center;
        color: $color--white;
        z-index: map-get($advent-calendar-z-index, modal-close);
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 4px;
          #{$ldirection}: 14px;
          height: 22px;
          width: 3px;
          background-color: $color--black;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
        &:hover {
          background: transparent;
          text-decoration: none;
          &:before,
          &:after {
            box-shadow: 0 0 10px $color--black;
          }
        }
      }
    }
  }
}
