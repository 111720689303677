$ab-small-down: max-width $width-large - 2;

.appt-book-sections-container {
  .location-select-section {
    .virtual__appointment--btn {
      height: 60px;
      line-height: 60px;
      font-size: 18px;
      width: 380px;
      font-family: $ano-bold_regular-font;
      @include breakpoint($ab-small-down) {
        width: 100%;
      }
    }
  }
  .appt-book-section-content {
    .confirm-container {
      .appt-book-book-submit {
        font-family: $ano-bold_regular-font;
      }
    }
  }
}

.appt-book {
  .confirm {
    .mobile-phone-fieldset {
      .appt-book-mobile-prefix {
        width: 30% !important;
      }
      .appt-book-mobile {
        width: 70% !important;
      }
    }
  }
}
