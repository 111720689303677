.payment-info {
  &-page {
    padding-bottom: 1.5em;
    .grid_container {
      @include breakpoint($bp--medium-up) {
        @include layout($grid--2, 1em) {
          .payment-info__default {
            @include grid-span(1, 1);
          }
          .payment-info__others {
            @include grid-span(1, 2);
          }
        }
      }
    }
  }
  &__subheader {
    margin: 1em 0 0;
  }
  &__default,
  &__others {
    .column {
      margin-top: 35px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.add-payment-page {
  padding-bottom: 1.5em;
  .payment_address,
  .payment_method {
    @include breakpoint($bp--medium-up) {
      width: 45%;
      min-width: 450px;
    }
  }
  .payment_address {
    margin-bottom: 2em;
  }
  .address_controls {
    margin-top: 0.5em;
  }
}

.checkout {
  &.viewcart {
    .checkout-header__title {
      padding-left: 0;
      width: 100%;
    }
    #bottom-viewcart-buttons {
      .applepay-terms-button-container {
        border: $border;
        background: $color--gray--white;
        padding-left: 3px;
        width: 81%;
      }
      .applepay-privacy-container {
        padding-left: 10px;
        float: left;
        @include breakpoint($bp--xxlarge-up) {
          width: 60%;
        }
      }
      span.applepay-button-container {
        padding-left: 0;
        text-align: center;
        .apple-pay-button {
          color: $black;
        }
        @include breakpoint($bp--xlarge-down) {
          float: none;
          width: 90%;
          min-width: 90%;
          margin-left: 10px;
        }
        @include breakpoint($bp--xxlarge-up) {
          margin: 60px 0 0 10px;
        }
      }
      .divide {
        .form-item {
          @include breakpoint($bp--xlarge-down) {
            padding-top: 10px;
          }
          .terms_input {
            max-width: 85%;
          }
          .picker {
            &.picker-checkbox {
              .picker-handle {
                float: right;
                @include breakpoint($bp--xxlarge-up) {
                  margin: 65px 0 0;
                }
                @include breakpoint($bp--xlarge-down) {
                  margin: 91px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint($bp--large-up) {
  #shipping-panel {
    .field-container {
      max-width: 495px;
    }
    input[type='text'] {
      padding: 16px 12px;
    }
  }
  .checkout__content {
    #order-summary-panel {
      .order-summary__content {
        .label {
          width: 65%;
        }
      }
    }
  }
  .column {
    &.left {
      #shipmethod-panel {
        #checkout_shipmethod {
          .ship-method-group-label {
            width: 100%;
            float: left;
            .picker-radio {
              width: 50%;
              float: left;
              label {
                margin-top: 3px;
              }
            }
            .picker {
              margin-right: 0px;
              label {
                div {
                  &.overlay-wrapper {
                    display: inline;
                  }
                }
              }
              .picker-handle {
                margin-right: 10px;
              }
            }
          }
          .ship-method-collection-group,
          .ship-method-home-group {
            float: left;
          }
        }
        select {
          border: $border;
        }
        .select2-container {
          .select2-choice {
            border: $border;
          }
        }
      }
      #review-panel {
        .form-item {
          &.card-expiry-date {
            float: left;
            .select2-container {
              .select2-choice {
                .select2-chosen {
                  margin: 0 15px;
                }
              }
            }
          }
          &.card-type,
          &.card-number {
            width: 45%;
          }
          &.card-cvn {
            float: left;
            margin-left: 20px;
            input.cv_number_field {
              margin-right: 10px;
            }
          }
        }
        .small_select {
          width: 7em;
        }
        span {
          &.select2-arrow {
            right: 6px;
          }
          &.float-left {
            @include swap_direction(margin, 0 10px);
          }
        }
        input[type='text'] {
          padding: 16px 12px;
        }
        .field-container {
          max-width: 495px;
        }
        .select2-container {
          .select2-choice {
            .select2-arrow b:before {
              bottom: 8px;
            }
          }
        }
        select,
        .select2-choice {
          height: 46px;
          line-height: 3.9;
        }
        .gift-cards {
          width: 51%;
        }
        .msg-order-email {
          color: $color--red;
        }
        .place-order {
          @include swap_direction(margin, 25px 0 5px);
        }
      }
    }
  }
}

#checkout_payment {
  .card-type {
    background-repeat: no-repeat;
    background-size: 43px 28px;
    border: 1px solid $color--white;
    display: inline-block;
    height: 28px;
    width: 43px;
    opacity: 0.25;
    /* visa */
    &.card-type-001 {
      background-image: url('/media/export/images/checkout/visa.png');
    }
    /* mastercard */
    &.card-type-002 {
      background-image: url('/media/export/images/checkout/master-card.png');
    }
    /* amex */
    &.card-type-003 {
      background-image: url('/media/export/images/checkout/amex.png');
    }
    /* maestro UK and international */
    &.card-type-024,
    &.card-type-042 {
      background-image: url('/media/export/images/checkout/maestro.png');
    }
    /* visa electron */
    &.card-type-033 {
      background-image: url('/media/export/images/checkout/visa-electron.png');
    }
    &.deselected-card-type {
      opacity: 0.25;
    }
    &.selected-card-type {
      border-color: $color--gray--lightest;
      opacity: 1;
    }
  }
}

@include breakpoint($bp--medium-down) {
  #shipping-panel,
  #review-panel {
    input[type='text'],
    input[type='tel'] {
      padding: 14px 12px;
    }
    .email-and-sms-promotions {
      .promotions-container {
        p,
        .form-item {
          margin-bottom: 5px;
        }
      }
    }
  }
  .column {
    &.left {
      #review-panel {
        .form-container {
          .form-item {
            &.card-expiry-date {
              width: 54%;
              float: left;
              span.float-left {
                margin: 0 2px;
                padding-top: 12px;
              }
              .small_select {
                width: 44%;
              }
            }
          }
        }
        .select2-container {
          .select2-choice {
            height: 44px;
            line-height: 1;
            border: $border;
            .select2-arrow b:before {
              bottom: 8px;
            }
          }
        }
        select {
          height: 44px;
          line-height: 1;
          border: $border;
        }
        .card-cvn {
          width: 100%;
          .cv_number_field {
            width: 50%;
          }
          @include breakpoint($bp--large-up) {
            width: 43%;
            float: #{$rdirection};
          }
        }
      }
    }
  }
  #checkout-review-panel {
    .msg-order-email {
      color: $color--red;
    }
    .place-order {
      @include swap_direction(margin, 25px 0 5px);
      width: 100%;
    }
  }
  #shipmethod-panel {
    .ship-method-home-group {
      .select2-container {
        .select2-chosen {
          font-size: 11px;
          margin-left: 10px;
        }
        .select2-choice {
          .select2-arrow {
            right: 5px;
          }
        }
      }
      select {
        font-size: 11px;
      }
    }
    select {
      border: $border;
    }
  }
  .checkout__content {
    #viewcart-panel {
      .cart-item {
        margin: 5px 0;
      }
    }
  }
  .applepay-button-container {
    .picker {
      .picker-label {
        text-transform: none;
        line-height: 1.5;
      }
    }
  }
}
