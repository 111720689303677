.address-book {
  &-info {
    @include clearfix;
    margin: 1em 0;
    .address_data {
      margin-bottom: 0.5em;
    }
    .column {
      margin-bottom: 1em;
    }
    @include breakpoint($bp--medium-up) {
      @include layout($grid--2, 1em) {
        .address-book__default {
          @include grid-span(1, 1);
        }
        .address-book__others {
          @include grid-span(1, 2);
        }
      }
    }
  }
}

.address-form {
  &__header {
    @include heading--small;
    @include fontsize-rem($typesize--18px);
  }
  legend {
    display: none;
  }
}

#address-form-popover {
  .field-container--grid {
    .form-item:nth-of-type(2n + 1) {
      margin-right: 0;
    }
  }
}
