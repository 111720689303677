@import 'advent_calendar_capability/advent_calendar_capability_var';

@keyframes advent-calendar-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes advent-calendar-fadeinout {
  50% {
    opacity: 1;
  }
}

@keyframes advent-calendar-fadein {
  100% {
    opacity: 1;
  }
}

@keyframes advent-calendar-glowing {
  0% {
    box-shadow: 0 0 -5px 10px $advent-calendar-card-glow-color;
  }
  50% {
    box-shadow: 0 0 35px 10px $advent-calendar-card-glow-color;
  }
  100% {
    box-shadow: 0 0 -5px 10px $advent-calendar-card-glow-color;
  }
}

@keyframes advent-calendar-glitter {
  0% {
    -webkit-transform: scale(0.3) rotate(0deg);
    -ms-transform: scale(0.3) rotate(0deg);
    transform: scale(0.3) rotate(0deg);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(1) rotate(360deg);
    -ms-transform: scale(1) rotate(360deg);
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(0.3) rotate(720deg);
    -ms-transform: scale(0.3) rotate(720deg);
    transform: scale(0.3) rotate(720deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0.3) rotate(0deg);
    -ms-transform: scale(0.3) rotate(0deg);
    transform: scale(0.3) rotate(0deg);
    opacity: 0;
  }
}

@import 'advent_calendar_capability/advent_calendar_capability_formatter';
@import 'advent_calendar_capability/advent_calendar_capability_tout';
