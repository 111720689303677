/**
 * social-login
 * This file contains all styilngs needed for facebook login implementation.
 *
 */

.social-login {
  &__container {
    padding: 30px 0;
  }
  &__divider {
    margin-bottom: 20px;
  }
  &__terms {
    margin-top: 15px;
  }
  &__email_opt_in {
    margin-top: 10px;
  }
  .picker-label {
    float: none;
    text-align: $ldirection;
    margin-#{$ldirection}: 39px;
  }
  .tooltip {
    text-align: $ldirection;
    .tooltiptext-over,
    .tooltiptext-under {
      max-width: inherit;
    }
  }
}

.sign-in-page {
  .divider {
    .social-login__divider {
      position: static;
    }
  }
}
/* SignIn page styling */
.sign-in-page {
  @include breakpoint($bp--medium-up) {
    .field-container--grid .form-item {
      float: none;
      margin-right: 0;
      padding-right: 0;
      clear: both;
    }
  }
  .social-login-section {
    @include breakpoint($bp--xxlarge-up) {
      margin-top: 120px;
    }
    .social-terms__container {
      margin-#{$ldirection}: 15px;
      display: none;
      @include breakpoint($bp--medium-up) {
        display: block;
      }
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 65px;
      }
      .form-item.tooltip {
        margin-bottom: 0;
        margin-top: 10px;
        text-transform: initial;
      }
      .tooltip {
        text-align: $ldirection;
        .tooltiptext-over,
        .tooltiptext-under {
          max-width: inherit;
        }
      }
    }
    .picker-label {
      float: none;
      text-align: $ldirection;
      margin-#{$ldirection}: 39px;
    }
  }
  .social-login {
    &__email_opt_in {
      margin-top: 0;
      margin-#{$ldirection}: 15px;
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 65px;
      }
      .tooltip {
        text-align: $ldirection;
      }
    }
    .social-disclaimer-tooltip {
      margin-#{$ldirection}: 15px;
      @include breakpoint($bp--medium-up) {
        display: none;
      }
    }
    &__divider {
      margin-bottom: 8px;
    }
    &__container {
      padding: 14px 0;
    }
    &__terms {
      text-align: $ldirection;
      margin-top: 26px;
      padding-bottom: 3px;
    }
    label {
      text-transform: none;
      text-align: $ldirection;
    }
    .fb-disclaimer-error {
      margin-#{$ldirection}: 15px;
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 65px;
      }
    }
  }
}

#power_review_container {
  .social-login {
    &__terms {
      padding-bottom: 10px;
    }
    &__divider {
      @include breakpoint($bp--medium-up) {
        margin: 15px auto 0;
        &:before {
          top: 36px;
        }
      }
    }
  }
}
/* GNAV styling */
.social-login.gnav {
  .social-login__container {
    padding: 0;
  }
  .social-login__terms {
    @include swap_direction(padding, 0 0 10px 0);
    text-align: $ldirection;
    line-height: 1.7;
  }
  input#facebook__email-opt-in-register-panel,
  input#fb-field-disclaimer {
    width: auto;
    height: 13px;
  }
}
/* Order confirmation page */
#confirmation-page {
  .checkout__content {
    padding-#{$ldirection}: 0;
    @include breakpoint($bp--xsmall-up) {
      @include swap_direction(padding, 0 0 0 1em);
    }
  }
  .social-login {
    &__container {
      @include breakpoint($bp--medium) {
        text-align: center;
      }
    }
  }
}
/* Social info section on Account preferences page */
.social-info {
  @include breakpoint($bp--xxlarge-up) {
    padding-#{$ldirection}: 10px;
  }
  &__header {
    @include breakpoint($bp--xsmall-up) {
      text-align: #{$ldirection};
    }
  }
  &__content {
    padding-#{$ldirection}: 0;
    .fb-overlay-social-info {
      display: inline-block;
    }
  }
  &__connect {
    .fb-disclaimer {
      float: unset;
    }
  }
}

.checkout {
  &__content {
    .signin-panel,
    .sign-in-panel {
      .social-login {
        &__title {
          display: none;
        }
        &__container {
          padding: 4px 0;
        }
        &__terms {
          text-align: $ldirection;
          padding-#{$ldirection}: 0;
          margin-top: 15px;
          p {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

.confirm-container {
  .social-login {
    &__container {
      padding: 16px 0px;
    }
    &__divider {
      @include swap_direction(margin, 2px auto 14px);
    }
    &__terms {
      margin-top: 26px;
    }
  }
}

.site-header {
  .site-header__tools {
    .site-my-mac__contents {
      overflow-y: scroll;
      height: 500px;
      width: 400px;
    }
  }
}
/* Appointment booking page */
.appt-book {
  .social-login {
    &__email-opt-in {
      margin-top: 10px;
      text-align: $ldirection;
    }
    &__opt-in-label {
      vertical-align: top;
      display: inline;
      margin-#{$ldirection}: 0;
    }
    &__terms {
      text-align: $ldirection;
      padding: 0;
    }
    #facebook__email-opt-in-register-panel {
      width: 20px;
      display: inline-block;
      height: 18px;
      margin: 0;
    }
    .tooltip {
      .tooltiptext-over {
        line-height: 14px;
      }
    }
    .fb-disclaimer {
      padding-#{$ldirection}: 8px;
    }
  }
}

.my-appointments {
  .fb-disclaimer {
    padding-#{$ldirection}: 8px;
  }
}

.fb-disclaimer-error {
  color: $color--hot--brown;
  padding-bottom: 10px;
  text-transform: uppercase;
  text-align: $ldirection;
}

.fb-overlay-container {
  width: 265px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.fb-disclaimer-container {
  display: flex;
  margin-top: 15px;
  .fb-disclaimer {
    display: block;
    margin-#{$ldirection}: 0;
    text-align: $ldirection;
    p {
      text-align: $ldirection;
      margin: 0;
      padding-#{$ldirection}: 8px;
      line-height: 17px;
    }
  }
}

.fb-overlay-social-info {
  width: 164px;
  height: 22px;
  position: relative;
  display: inline-flex;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}
