.cards {
  .card.tout {
    background-color: transparent;
    z-index: map-get($advent-calendar-z-index, tout);
  }
  .card.tout-1-2 {
    max-width: calc(#{$advent-calendar-card-width-mobile} * 2 + 2 * #{$advent-calendar-card-margin});
  }
  .card.tout-1-3 {
    max-width: calc(#{$advent-calendar-card-width-mobile} * 3 + 4 * #{$advent-calendar-card-margin});
  }
  .card.tout[data-tout-position]::before,
  .card.tout[data-tout-position]::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    background-repeat: no-repeat;
    background-position: center;
  }
  .card.tout[data-tout-position]::after {
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    background-size: 100% 100%;
    background-image: url($advent-calendar-glitter-image);
    overflow: hidden;
    z-index: 2;
    color: transparent;
    -moz-opacity: 0;
    opacity: 0;
    -webkit-animation: advent-calendar-glitter 2s linear 0.25s infinite normal;
    -moz-animation: advent-calendar-glitter 2s linear 0.25s infinite normal;
    -ms-animation: advent-calendar-glitter 2s linear 0.25s infinite normal;
    -o-animation: advent-calendar-glitter 2s linear 0.25s infinite normal;
    animation: advent-calendar-glitter 2s linear 0.25s infinite normal;
  }
  .card.tout[data-tout-position='3'] {
    &::before {
      background-image: url('/media/export/cms/advent_calendar/bow-mobile-blue.png');
      width: 164px;
      height: 168px;
    }
    &::after {
      top: -20%;
      left: -2%;
      animation-duration: 1.5s;
      animation-delay: 1s;
    }
  }
  .card.tout[data-tout-position='5'] {
    &::before {
      background-image: url('/media/export/cms/advent_calendar/bow-mobile-green.png');
      width: 182px;
      height: 187px;
      left: 25%;
    }
    &::after {
      content: none;
    }
  }
  .card.tout:nth-child(8) {
    &::before {
      background-image: url('/media/export/cms/advent_calendar/bow-mobile-orange.png');
      width: 152px;
      height: 160px;
      left: 75%;
    }
    &::after {
      content: none;
    }
  }
  .card.tout[data-tout-position='7'] {
    &::before {
      background-image: url('/media/export/cms/advent_calendar/bow-mobile-blue-small.png');
      width: 116px;
      height: 121px;
      top: 35%;
      left: 25%;
    }
    &::after {
      top: -20%;
      left: -5%;
      animation-duration: 2s;
      animation-delay: 1.5s;
    }
  }
  .card.tout[data-tout-position='8'] {
    &::before {
      background-image: url('/media/export/cms/advent_calendar/bow-mobile-green.png');
      width: 160px;
      height: 165px;
    }
    &::after {
      top: -20%;
      left: -5%;
      animation-duration: 2s;
      animation-delay: 2s;
    }
  }
  .card.tout[data-tout-position='9'] {
    &::before {
      background-image: url('/media/export/cms/advent_calendar/bow-mobile-purple.png');
      width: 152px;
      height: 147px;
      left: 20px;
    }
    &::after {
      top: -20%;
      left: -5%;
      animation-duration: 2.5s;
      animation-delay: 1.5s;
    }
  }
  .card.tout[data-tout-position='11'] {
    &::before {
      background-image: url('/media/export/cms/advent_calendar/bow-mobile-pink.png');
      width: 154px;
      height: 157px;
    }
    &::after {
      top: -5%;
      left: 23%;
      animation-duration: 2.5s;
      animation-delay: 1s;
    }
  }
  .card.tout[data-tout-position='12'] {
    &::after {
      content: none;
    }
  }
  .card.tout:last-child {
    display: none;
  }
  @include breakpoint($bp--xxsmall-up) {
    .card.tout[data-tout-position='3'] {
      &::before {
        width: 164px;
        height: 168px;
      }
    }
    .card.tout[data-tout-position='5'] {
      &::before {
        left: 50%;
      }
    }
    .card.tout:nth-child(8) {
      &::before {
        width: 152px;
        height: 160px;
      }
    }
    .card.tout[data-tout-position='7'] {
      &::before {
        width: 116px;
        height: 121px;
        top: 35%;
        left: 25%;
      }
    }
    .card.tout[data-tout-position='9'] {
      &::before {
        width: 152px;
        height: 147px;
        left: 50%;
      }
    }
    .card.tout:last-child {
      display: block;
    }
  }
  @include breakpoint($bp--large-up) {
    .card.tout-1-2 {
      max-width: calc(#{$advent-calendar-card-width-desktop} * 2 + 2 * #{$advent-calendar-card-margin});
    }
    .card.tout-1-3 {
      max-width: calc(#{$advent-calendar-card-width-desktop} * 3 + 4 * #{$advent-calendar-card-margin});
    }
    .card.tout[data-tout-position='3'] {
      &::before {
        background-image: url('/media/export/cms/advent_calendar/bow-desktop-orange@2x.png');
        width: 240px;
        height: 257px;
      }
      &::after {
        top: -10%;
        left: 6%;
      }
    }
    .card.tout[data-tout-position='5'] {
      &::before {
        background-image: url('/media/export/cms/advent_calendar/bow-desktop-pink@2x.png');
        width: 182px;
        height: 187px;
      }
    }
    .card.tout:nth-child(8) {
      &::before {
        background-image: url('/media/export/cms/advent_calendar/bow-side-right-silver.png');
        width: 130px;
        height: 138px;
        left: 65%;
        top: 65%;
      }
    }
    .card.tout[data-tout-position='7'] {
      &::before {
        background-image: url('/media/export/cms/advent_calendar/bow-desktop-purple@2x.png');
        width: 238px;
        height: 241px;
        left: 50%;
        top: 50%;
      }
      &::after {
        top: -10%;
        left: 10%;
      }
    }
    .card.tout[data-tout-position='8'] {
      &::before {
        background-image: url('/media/export/cms/advent_calendar/bow-desktop-green@2x.png');
        width: 259px;
        height: 269px;
      }
      &::after {
        top: 0;
        left: 30%;
      }
    }
    .card.tout[data-tout-position='9'] {
      &::before {
        background-image: url('/media/export/cms/advent_calendar/bow-desktop-blue@2x.png');
        width: 251px;
        height: 265px;
      }
      &::after {
        top: -10%;
        left: 5%;
      }
    }
    .card.tout[data-tout-position='11'] {
      &::before {
        background-image: url('/media/export/cms/advent_calendar/bow-desktop-silver@2x.png');
        width: 231px;
        height: 246px;
      }
      &::after {
        content: none;
      }
    }
    .card.tout:last-child {
      display: block;
    }
  }
  @include breakpoint($bp--xxlarger-up) {
    .card.tout:nth-child(8) {
      &::before {
        content: none;
      }
    }
    .card.tout[data-tout-position='8'] {
      &::before {
        top: -50px;
        left: calc(50% - 10px);
      }
      &::after {
        top: -80%;
      }
    }
    .card.tout[data-tout-position='9'] {
      &::before {
        width: 251px;
        height: 265px;
        top: 100%;
        left: 50%;
      }
      &::after {
        top: 40%;
      }
    }
  }
}
