.product--teaser {
  .product__footer {
    .product__price {
      display: block;
    }
    .product-item__add-to-cart {
      .product-item__unavailable-message {
        position: relative;
        top: -20px;
      }
      .notify-status {
        .notify_me {
          top: -1px;
        }
      }
    }
  }
}

.account-order-history {
  .past-purchases {
    .tracking-link-list {
      position: relative;
      .tracking_disclaimer {
        display: none;
        max-width: 400px;
      }
    }
    .tracking_number:hover .tracking_disclaimer {
      display: block;
      background-color: $color--white;
      color: $color--gray--dark;
      border: 1px solid $color--black;
      border-radius: 6px;
      font-size: 11px;
      width: 270px;
      text-transform: unset;
      position: absolute;
      bottom: 20px;
    }
  }
}

.account-nav .sidebar-profile-pic {
  float: none;
}

#cboxWrapper {
  #address-form-popover {
    @include breakpoint($bp--small-up) {
      .address_form_container {
        width: 100%;
      }
      .QAS_SEARCH_AGAIN {
        margin-bottom: 1em;
      }
    }
  }
}

#address-form-popover {
  @include breakpoint($bp--small-up) {
    .address_form_container {
      width: 50%;
    }
  }
  .select2-choice {
    border: 0;
    padding-left: 1em;
    text-decoration: none;
  }
  .form-submit,
  .checkout-buttons {
    width: 228px;
  }
}

#myaccount-wrapper {
  .form_element {
    margin-bottom: 1em;
  }
  .button {
    min-width: 228px;
  }
  select {
    text-decoration: none !important;
  }
}

.profile-page {
  .btn {
    min-width: 228px;
  }
}

.sign-in-page,
.password-request {
  .btn {
    min-width: 228px;
  }
}

.favorites-board__list {
  .product__detail {
    .product__footer {
      .product__size {
        display: block;
        position: absolute;
        top: -32px;
      }
    }
  }
}

.account-page {
  .orders-list {
    .orders-list__table {
      position: relative;
      .tracking_disclaimer {
        display: none;
      }
      .order-detail-item-link:hover .tracking_disclaimer {
        display: block;
        background-color: $color--white;
        color: $color--gray--dark;
        border: 1px solid $color--black;
        border-radius: 6px;
        font-size: 11px;
        width: 270px;
        text-transform: unset;
        position: absolute;
        top: 20px;
        max-width: 400px;
      }
    }
  }
}

.address-book-page__content,
.payment-info-page,
.add-payment-page {
  padding: 1em;
}

.add-payment-page {
  .payment_method {
    .form_element {
      margin-bottom: 1em;
    }
  }
  .select2-container,
  .field {
    margin-bottom: 1em;
    text-decoration: none;
  }
  #continue-btn,
  .btn {
    width: 228px;
  }
}

.order-details-page__content {
  padding: 10px;
}
/* Local override for Mobile display on recent orders page */
@media (max-width: 48em) {
  .account-order-history
    .past-purchases
    .past-purchases__item
    .product--teaser
    .product__add
    .prod_mobile_add_override {
    font-size: 1.1rem;
    width: 100%;
    padding: 0;
    height: 3em;
    line-height: 3em;
  }
  .account-order-history .past-purchases .past-purchases__item .product--teaser .product__add {
    width: 100%;
    clear: both;
  }
  .account-order-history .past-purchases .past-purchases__item .product-header .quantity {
    margin-left: 43%;
  }
  .account-order-history .past-purchases .past-purchases__item .product-header .price {
    width: 14%;
  }
  .account-order-history .past-purchases .past-purchases__item .product-header .rating {
    margin-left: 67%;
  }
  .account-order-history .past-purchases .past-purchases__item .product--teaser .product__price {
    margin-left: 85%;
  }
  .account-order-history .past-purchases .past-purchases__item .product--teaser .product__rate {
    margin-left: 69%;
  }
  #QAS_NODE h5 {
    margin-bottom: 0.5em;
    text-align: center;
  }
}

.header-search__term-result {
  .search-count {
    display: none;
  }
}

.password-field {
  position: relative;
  &__info {
    @include breakpoint($bp--medium-up) {
      position: absolute;
      left: 105%;
      bottom: 11%;
      &-reset {
        bottom: auto;
        top: 17%;
      }
    }
    width: 100%;
    @include breakpoint($bp--small-up) {
      border: 1px solid $color--gray--dark;
    }
    background-color: $color--white;
    z-index: 99;
    margin-top: 5px;
    &::before {
      @include breakpoint($bp--medium-up) {
        content: '';
        position: absolute;
        top: 43%;
        right: 100%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent $color--gray--dark transparent transparent;
      }
    }
  }
  &__rules {
    column-count: 1;
    @include breakpoint($bp--small-down) {
      column-count: 2;
    }
    padding: 10px;
    font-size: 0.8em;
    list-style: none;
    > li {
      display: flex;
      align-items: center;
      padding: 3px 0;
      color: $color--gray--dark;
      transition: 0.2s;
      white-space: nowrap;
      &::before {
        content: '\2715';
        display: inline-block;
        color: $color--red-tier2;
        font-size: 1em;
        line-height: 0;
        margin: 0 6px 0 0;
        transition: 0.2s;
      }
      &.pass {
        color: $color--success;
        &::before {
          color: $color--success;
          content: '\2713';
          text-shadow: 0 0 8px rgba($color--black, 0.5);
        }
      }
    }
  }
}

.password-request-page {
  &__content {
    @include breakpoint($bp--xlarge-up) {
      width: 88%;
    }
    .account-page {
      &__title {
        text-align: left;
      }
    }
    #password_reset_send {
      .form-item {
        &.email-address {
          font-family: $roboto-mono_bold-font;
        }
      }
    }
  }
}

.password-sent-page {
  &__content {
    .account-page {
      &__title {
        text-align: left;
      }
    }
    .sent-info__email {
      font-family: $roboto-mono_bold-font;
    }
  }
}

.password-reset-page {
  .account-page {
    &__title {
      text-align: left;
    }
  }
}

.sign-in-page {
  .error_messages {
    .server {
      text-transform: initial;
    }
  }
}

.email-signup-new-user {
  .mobile-signup {
    input[type='text'] {
      width: 80%;
      margin-#{$rdirection}: 0;
      @include breakpoint($bp--large-up) {
        width: 73%;
      }
    }
  }
}
