.sidr {
  .menu-item-container[menu-sub='999'] {
    padding-top: 0;
  }
  &-open {
    #mobile-nav {
      .icon--bars {
        @include thick-close();
        top: 2px;
        right: -2px;
        width: 25px;
        height: 25px;
      }
    }
  }
}

.async-hide {
  opacity: 0;
}

.loyalty-disabled {
  pointer-events: none;
  background: #{$color--gray--lightest};
}

.mac-early-access-popup {
  &__congratulation-button {
    margin-top: 50px;
  }
}

.product-full {
  &__add-to-bag {
    .product-add-to-bag {
      &.loyalty-disabled {
        @include swap_direction(margin, 0);
        height: 60px;
        line-height: 4;
      }
    }
  }
}

.site-footer {
  margin-top: 50px;
  @include breakpoint($bp--large-up) {
    margin-top: 0;
  }
  .be-ix-link-block {
    #{$ldirection}: 25px;
    bottom: 700px;
    position: absolute;
    @include breakpoint($bp--large-up) {
      bottom: 470px;
      width: #{$width-xxlarge};
    }
    @include breakpoint($bp--medium-landscape) {
      bottom: 500px;
      width: #{$width-xlarge};
    }
  }
  .be-label {
    color: #{$color--black};
    display: inline-block;
    margin-#{$rdirection}: 40px;
  }
  .be-list {
    @include breakpoint($bp--large-up) {
      display: inline-block;
    }
  }
  .be-list-item {
    background-color: #{$color--white};
    color: #{$color--black};
    display: inline-block;
    margin-#{$rdirection}: 40px;
    .be-related-link {
      color: #{$color--black};
    }
  }
}

/*
  Overriding the Afterpay/Clearpay styles from base to align with Klarna styling for UK.
  Level of specificity is required due to base specificity.
*/
.site-container {
  .product-full--enhanced {
    &.product-full {
      .product-full__details-container {
        .product-full__sku-details {
          .product-full__installment-price-wrapper {
            margin-top: 15px;
            padding: 0;
            .product-full__installment_price {
              .afterpay-paragraph {
                align-items: center;
                gap: 6px;
                margin: 0;
              }
              .afterpay-text1 {
                display: block;
                margin: 0;
                max-width: none;
                width: auto;
                max-width: 67%;
              }
              .Clearpay-logo {
                margin: 0;
              }
              .afterpay-link {
                background-color: $color-text-grey;
                background-image: url('#{$base-theme-path}svg-icons/src/icon--info.svg');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 7px;
                filter: invert(1);
                height: 13px;
                margin: 0;
                text-indent: -999em;
                width: 13px;
              }
              .afterpay-logo-link {
                display: flex;
                gap: 6px;
              }
            }
          }
          .product-full {
            &__inventory-status {
              order: 1;
            }
          }
        }
        .product-foundation-vto__btn {
          order: 2;
        }
      }
    }
  }
}

.select-address {
  .sms-info {
    display: block;
    font-size: 14px;
    margin: 5px 0;
  }
  .phone_1_container {
    .field {
      &::placeholder {
        text-transform: lowercase;
      }
    }
  }
}
