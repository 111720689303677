/* NOTIFICATIONS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */
/* DEFAULT CONTAINER STYLE */
.bt-overlay-bg {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: black;
  opacity: 0.9;
}

#bt_notification div.bt-content {
  position: relative;
  border: 1px solid black;
  padding: 15px;
  z-index: 9999;
  font-size: 12px;
  line-height: 16px;
  background-color: $color--black;
  color: $color--white;
}

#bt_notification div.bt-content a.bt-close-link {
  position: absolute;
  top: 10px;
  right: 10px;
}
/* CUSTOM POSITIONING */
#bt_notification div.bt-bottom-right {
  position: fixed;
  border-bottom-width: 0;
  bottom: 0px;
  right: 10px;
  width: 310px;
}

#bt_notification div.bt-inner-content {
  float: left;
  .bt-pp-section {
    margin-bottom: 20px;
  }
}

#bt_notification .bt_contentarea {
  margin-top: 10px;
}

#bt_notification div.bt-bottom-full {
  position: fixed;
  bottom: 0px;
  right: 0px;
  border-bottom-width: 0;
}
/* DEVICES */
#bt_notification div.bt-content-mobile {
  padding: 10px;
}

#bt_notification div.bt-content-mobile a.bt-close-link {
  display: none;
}
/* BRAND SPECIFICS @JM */
#bt_notification div.bt-content {
  padding: 20px;
  .btn {
    border: 1px solid $color--white;
    display: block !important;
    margin-top: 10px;
    text-transform: uppercase;
    &:not(:hover) {
      text-decoration: none;
    }
  }
}

#bt_notification div.bt-content a {
  font-weight: bold;
  border: 0;
  text-decoration: underline;
  color: $color--white;
  text-transform: none;
}

#bt_notification .icon--remove {
  font-size: 22px;
}

#bt_notification .icon--remove:before {
  color: $color--black !important;
}

.overlay-close-container {
  float: right;
}

#bt_notification div.bt-content a.bt-close-link {
  display: block;
  width: 15px;
  height: 15px;
  background: url('/media/export/images/cookies/close_x.png') 50% 50% no-repeat;
  text-indent: -9999em;
}
/* SETTINGS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_settings div.bt-content {
  border: 1px solid black;
  padding: 15px;
  background-color: white;
  z-index: 1001;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 1.5em;
}

#bt_settings div.bt-content div.bt-intro {
  display: block !important;
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy {
  display: block !important;
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy p {
  margin-top: 1em;
}

#bt_settings {
  display: block !important;
}
/* TOGGLE SLIDER */
#bt_settings div.bt-content div.slide,
#bt_settings div.bt-content ul.slide-toggle {
  position: relative;
  overflow: hidden;
  width: 525px;
}

#bt_settings div.bt-content div.slide {
  margin: 20px 0;
  display: block !important;
}

#bt_settings div.bt-content ul.slide-toggle {
  list-style: none;
  margin: 0;
  padding: 0;
  background: transparent url('/media/export/images/cookies/all-off.png') 50% 100% no-repeat;
  background-size: contain;
}

#bt_settings div.bt-content div.mask {
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 0px;
  display: block !important;
  border-right: 1px solid #eee;
}

#bt_settings div.bt-content div.mask ul.slide-toggle {
  background-image: url('/media/export/images/cookies/all-on.png');
}

#bt_settings div.bt-content div.mask div.drag {
  position: absolute;
  bottom: 12px;
  right: 70px;
  width: 26px;
  height: 11px;
  background: transparent url('/media/export/images/cookies/icon_arrow.png') 50% 100% no-repeat;
}

#bt_settings div.bt-content ul.slide-toggle li {
  cursor: pointer;
  float: left;
  width: 160px;
  height: 145px;
  list-style-type: none;
}

#bt_settings div.bt-content ul.slide-toggle li.middle {
}

#bt_settings div.bt-content ul.slide-toggle li.selected {
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: block;
  font-weight: bold;
  text-align: center;
  margin: 5px;
}
/* PRIVACY INFORMATION */
#bt_settings div.bt-content .bt-privacy-info {
  display: none;
  overflow: hidden;
}

#bt_settings div.bt-content .bt-privacy-info.selected {
  display: block !important;
}

#bt_settings div.bt-content .bt-privacy-info h3 {
  padding: 0.5em 1em 0.5em 40px;
  background-position: 10px 50%;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
  font-size: 20px;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will h3 {
  background-image: url('/media/export/images/cookies/icon_safe_lock.png');
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot h3 {
  background-image: url('/media/export/images/cookies/icon_unsafe_lock.png');
}

#bt_settings div.bt-content .bt-privacy-info div {
  width: 48%;
  margin-right: 2%;
  float: left;
  display: block !important;
}

#bt_settings div.bt-content .bt-privacy-info div ul {
  list-style-type: none;
  padding-left: 0px !important;
  margin: 1em;
}

#bt_settings div.bt-content .bt-privacy-info div ul li {
  margin: 0.8em 0;
  padding-left: 30px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  list-style-type: none;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will ul li {
  background-image: url('/media/export/images/cookies/icon_safe_thick.png');
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot ul li {
  background-image: url('/media/export/images/cookies/icon_unsafe_thick.png');
}

.bt-find-more {
  text-decoration: underline;
}
/* CUSTOM POSITIONING */

/* DEVICES */
#bt_settings div.bt-content-mobile ul.slide-toggle {
  background-image: url('/media/export/images/cookies/m_all-off.png');
}

#bt_settings div.bt-content-mobile div.mask ul.slide-toggle {
  background-image: url('/media/export/images/cookies/m_all-on.png');
}

#bt_settings div.bt-content-mobile div.slide {
  margin: 20px auto;
}

#bt_settings div.bt-content-mobile div.slide,
#bt_settings div.bt-content-mobile ul.slide-toggle {
  width: 246px;
  padding-left: 0 !important;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li {
  width: 82px;
  height: 136px;
}

#bt_settings div.bt-content-mobile div.mask div.drag {
  right: 36px;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li span {
}

#bt_settings div.bt-content-mobile .bt-privacy-info div {
  width: 100%;
  float: none;
}
/* BRAND SPECIFICS */
#bt_settings div.bt-content {
  border-color: black;
  border-width: 2px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

#bt_settings div.bt-content-desktop {
  padding: 2em;
  height: 100%;
  display: block !important;
}

#bt_settings div.bt-content-mobile {
  display: block !important;
}

#bt_settings div.bt-content div.bt-intro h2 {
  padding: 0;
  margin: 0 0 1em 0;
  border: none;
  text-transform: none;
  color: black;
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: none;
}

#bt_settings div.bt-content-mobile div.slide,
#bt_settings div.bt-content-mobile div.mask div.drag {
  right: 30px;
  bottom: 11px;
}
