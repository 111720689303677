.checkout {
  .cart-item {
    &__qty,
    &__price {
      &-label {
        display: none;
      }
    }
    &__remove-form a,
    .remove-sample {
      @include body-mono();
    }
    &__bundle-link {
      pointer-events: none;
    }
  }
  &__content {
    .loading {
      // the following are override styles, for mac_base styling, to get loading
      // messages to appear properly for UK's OPC implementation
      text-transform: none;
      margin: 1em 0;
      @include breakpoint($bp--medium-up) {
        width: 100%;
        float: none;
        margin-left: 0;
        margin-right: 0;
        padding-right: 0;
      }

      // this bit of styling comes from the BrZ UK1 implementation, to set up
      // loading message styling as it should be
      background: url(/media/images/common/ajax-loading.gif) no-repeat center center;
      * {
        opacity: 0.8;
        filter: alpha(opacity=80);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
      }
    }
  }
  &__sidebar {
    .product {
      background: #f0f0f0;
      padding: 1em;
    }
  }
}

.cart-item {
  // breaking this out, for better DRY implementation
  &.sample &__remove-form {
    .checkout & {
      // changing the way the below is implemented, to provide the easiest way to keep inline content in this element's
      // container on a different line from the remove sample link (a sibling of this element, and an inline element),
      // when this element shouldn't shown
      //display: none;
      visibility: hidden;
      height: 0;
    }
  }
}

.select2 {
  &-container &-choice &-arrow {
    b {
      .checkout__content .product & {
        @include icon(caret-down);
        font-size: 24px;
        &:before {
          @include transform(rotate(-45deg));
        }
      }
    }
  }
}

.kit-container {
  @include swap_direction(margin, 0 85px 0 17%);
  @include swap_direction(padding, 90px 0 0 0);
  @include breakpoint($bp--medium-down) {
    @include swap_direction(margin, 0 0 0 17%);
    @include swap_direction(padding, 10px 0 0 0);
    clear: both;
  }
  .checkout__sidebar & {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    clear: both;
  }
  .cart-item {
    &__desc-info {
      @include swap_direction(margin, 0 0 20px 0);
      @include swap_direction(padding, 0 0 20px 0);
      border-bottom: 1px solid #{$color--black};
      &:last-child {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
        border-bottom: none;
      }
    }
    .swatch {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      display: inline-block;
      position: relative;
      top: 6px;
    }
    .shade {
      @include swap_direction(padding, 0 0 0 5px);
      display: inline-block;
      &-label {
        display: none;
      }
    }
    .checkout__sidebar & {
      @include swap_direction(margin, 0);
    }
  }
}

.order-details-page {
  .virtual-bundling {
    .kit-container {
      @include swap_direction(margin, 0 0 0 17%);
      @include breakpoint($bp--medium-down) {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 15px 0 0 0);
      }
    }
    .kit-item {
      border-bottom: none;
      .cart-item {
        &__total {
          display: none;
        }
      }
    }
    .cart-item {
      &__total {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0 4.8% 0 0);
        @include breakpoint($bp--medium-down) {
          float: #{$ldirection};
        }
        float: #{$rdirection};
        width: auto;
      }
    }
  }
}
