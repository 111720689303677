#promo-panel {
  .lipstick_day {
    text-align: center;
    padding: 1em 0;
    .media-block__media {
      img {
        width: auto;
      }
    }
  }
}

.checkout-panel--samples {
  @media (min-width: 640px) {
    padding: 0 15px;
  }
  .checkout-panel__header {
    .checkout-panel__heading,
    .checkout-panel__message {
      margin: 1em 0;
      text-align: center;
    }
  }
  .samples {
    .product {
      float: left;
      width: 100%;
      margin-bottom: 15px;
      @media (min-width: 481px) {
        width: 50%;
        margin-bottom: 45px;
        padding: 0 10px;
      }
      @media (min-width: 641px) {
        width: 33.33333%;
      }
      .details {
        position: relative;
        min-height: 90px;
        border-top: 1px solid #c7c7c7;
      }
      .product-brief__title {
        line-height: normal;
        font-size: 18px;
        font-size: 1.5rem;
      }
      .product-brief__subtitle,
      .product-shade {
        line-height: 1.25;
        margin: 15px 0 15px 0;
        text-transform: uppercase;
        @media (min-width: 481px) {
          margin: 6px 80px 6px 0;
        }
      }
      .product-size {
        float: right;
        margin-right: 5px;
        @media (min-width: 1023px) {
          margin-top: 0;
          margin-right: 0;
          position: absolute;
          top: 1.2em;
          right: 0;
        }
      }
      .product-img {
        text-align: center;
      }
      .form-item {
        padding: 1em 0;
        text-align: right;
      }
      .sample-select-button {
        display: inline-block;
        width: auto;
        height: 30px;
        padding: 0 20px;
        font-family: $headline-font-family;
        font-size: 15px;
        text-align: center;
        line-height: 30px;
        color: black;
        background-color: #f0f0f0;
        border: solid 1px black;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
  .bottom {
    overflow: hidden;
    margin: 0 0 15px;
    .continue-shopping__button {
      width: 100%;
    }
    @media (min-width: 640px) {
      .continue-shopping__button {
        float: right;
        min-width: 228px;
        width: auto;
      }
    }
  }
}
