$bp--ipad: (min-width 1025px);

.spp-workswith-v1 {
  .expando-block__content {
    .spp-workswith__products {
      .product {
        .product__detail {
          .product__header {
            .product__price_ppu {
              display: none;
            }
          }
        }
      }
    }
  }
}

.delivery_cntdwn_c.show-xlarge-up--block {
  @include breakpoint($bp--largest-up) {
    /* The use of !important is used a lot because somewhere
    ** the properties are created in inline code and this element
    ** can't be overwritten without them. */
    float: none !important;
    margin: 0 auto;
    width: auto !important;
    max-width: 100% !important;
    position: -webkit-sticky !important; /* Safari */
    position: sticky !important;
    height: 0px;
    .cntdwn {
      margin-top: 15px;
    }
  }
}

.product-full {
  &__sku-details {
    @include swap_direction(margin-bottom, 15px);
  }
  &__installment_price {
    .afterpay-paragraph {
      .Clearpay-logo {
        @include swap_direction(margin, 0 5px);
        width: 115px !important;
        max-width: 115px !important;
        position: relative;
        bottom: 2px;
      }
      .afterpay-link {
        font-size: 11px !important;
        background: $black;
      }
    }
  }
}

.product__share--text {
  display: none;
}

.product__description-group-body {
  .active & {
    position: unset !important;
  }
}

.product__social-links {
  display: inline-block;
  &.social_icon_hidden {
    width: auto;
    .product__social-link--email {
      margin-right: 0px;
    }
    .product__social-link--facebook,
    .product__social-link--twitter,
    .product__social-link--pinterest {
      display: none;
    }
  }
}

.artist-favorite-products-v1 {
  .artist--products {
    .artist--products__carousel {
      .slick-list {
        &.draggable {
          height: 500px;
        }
      }
    }
  }
}

.contact-us-page {
  .email-customer-service-form {
    .question_type {
      padding-top: 15px;
    }
  }
  .details {
    &__header {
      padding-bottom: 5%;
    }
  }
}

.product--teaser {
  .product__header {
    .product__price_ppu {
      display: none;
    }
  }
  .product__footer {
    padding-top: 0px;
    .product__add-to-bag {
      margin-top: 10px;
    }
    padding-bottom: 13px;
    .product__price_ppu {
      position: absolute;
      bottom: 0px;
      width: 55%;
    }
  }
}

.favorites-board__item {
  .product--teaser {
    .product__footer {
      .product__price_ppu {
        top: 50px;
      }
    }
  }
}

#cboxClose {
  width: 48px;
}

.page--spp__product {
  .product__detail {
    .product__footer {
      .product__edd {
        display: none;
      }
      .product__inventory-status {
        li.product__inv-status-item.coming-soon {
          margin: 15px 0;
        }
        a.notify_me {
          font-size: 1.5em;
          height: 60px;
          line-height: 64px;
          padding: 0;
          position: relative;
          width: 100%;
        }
        .coming-soon_text {
          position: relative;
        }
      }
    }
  }
}

.checkout-confirmation-page {
  .header__section {
    .order_id {
      color: $color--white;
    }
  }
}

.footer-menu--pc {
  .liveperson {
    position: absolute;
  }
}

.footer-menu--pc {
  .liveperson img {
    position: absolute;
  }
}

#site-navigation {
  .site-navigation__carousel {
    .slick-dots {
      display: none;
    }
  }
}

.order-details-page {
  .order-products {
    .product {
      .product-info__spp-status {
        .product-info__text {
          margin-left: 16.66%;
        }
      }
    }
  }
}

.profile-pic-upload {
  padding: 12px;
  .profile-pic-upload__help {
    text-align: center;
    font-size: 1.2em;
  }
  .profile-pic-upload__form-wrapper {
    .btn-instructions {
      padding-left: 0px;
      #choose-file-btn {
        float: left;
        padding: 8px;
        text-align: center;
        width: 140px;
        margin: 0px;
        cursor: pointer;
        background-color: black;
        span {
          color: white;
          text-transform: uppercase;
        }
      }
      #file-chosen-state {
        float: left;
        padding: 6px 0;
        margin-left: 24px;
        text-align: center;
        border-bottom: 1px solid black;
        text-transform: uppercase;
      }
    }
    .btn-submit {
      clear: both;
      input {
        font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
      }
      .form-submit {
        margin-left: 0px;
      }
    }
  }
}

.artistry-video-player-v1 {
  .artistry-video-player__products {
    .video-player__products-carousel {
      .video-player__product-slide {
        .product__detail {
          .product__footer {
            .product__inventory-status {
              .product__inv-status-item.sold-out {
                @include swap_direction(margin, 43px 0 0 0);
              }
            }
          }
        }
      }
    }
  }
}

.address-form select {
  border-color: #999999;
}

.select2-container {
  border: 1px solid #999999;
  padding: 0;
}

.samples-panel {
  #checkout_samples {
    .samples-panel__submit-container {
      text-align: center;
    }
  }
}

.artistry--mosaic-tile {
  .text-wrap {
    min-height: 530px\9;
  }
}

.section-stores {
  .locations_map_panel {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan down'],
      div[title^='Pan right'],
      div[title^='Pan up'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.site-container {
  .block-system {
    .column-main {
      .custom-grid {
        max-width: 100%;
      }
    }
  }
  .address_form_container {
    .address * {
      margin: 5px 0;
    }
  }
  @media (min-width: 30.0625em) {
    .product-item__unavailable-message {
      float: right;
      height: 40px;
      margin: 0;
      color: $color--error;
    }
  }
  .checkout {
    .promotions-container {
      .email_promotions {
        .sms-promo-wrapper {
          .form-item {
            .sms_input {
              width: 80%;
            }
          }
        }
      }
    }
  }
  #profile_preferences {
    .sms-promotions {
      .sms-info__item {
        .sms_input {
          width: 80%;
        }
      }
    }
    .birth_day_content_nonloyalty {
      display: none;
      visibility: hidden;
      height: 0;
    }
  }
  .page--spp__custom_palette {
    .artist-tile-v1 {
      .artist-tile__info {
        position: static;
      }
    }
    .product__detail {
      .shade-picker__color-name {
        padding: 0;
        margin-top: 1.5px;
        white-space: nowrap;
        font-size: 8.5px;
      }
    }
    &.MAC_15_PALETTE {
      .product__detail-zone {
        .shade-picker__color-details {
          width: 100%;
        }
      }
    }
  }
  .page--spp__custom_palette.MAC_6_PRO_LONGWEAR_PALETTE {
    .product__detail-zones {
      padding: 29% 15% 0;
    }
    .product__detail-zone {
      height: 30%;
      padding: 3% 0 0 0;
    }
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  height: 230px;
  margin: 17px 7px 5px 7px;
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 20px;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
  .field .email_comp .email_input {
    width: 75%;
  }
}

.field {
  .email_comp {
    .email_input {
      float: left;
      width: 79%;
    }
    .align-r {
      float: left;
      margin-left: 10px;
      .input-btn {
        height: 58px;
      }
    }
  }
  .terms_condition_cont {
    float: right;
    margin: 10px 5px 0 0;
  }
}

.favorites-page {
  &__content {
    .favorites-mylists {
      @include breakpoint($bp--large-up) {
        display: none;
      }
      @include breakpoint($bp--ipad) {
        display: block !important;
      }
    }
  }
}

.product--teaser {
  .product__footer {
    float: none;
    border-top: $border;
    padding-top: 18px;
    padding-bottom: 0;
    .product__price {
      display: none;
    }
    @include breakpoint($bp--xlarge-up) {
      .product__price {
        display: inline-block;
        float: left;
        line-height: get-line-height(15px, 28px); // approx size of inline button nearby
      }
    }
    .product__add-to-bag {
      margin-top: 0;
    }
    .product__images {
      @include breakpoint($bp--small-up) {
        bottom: 70px;
      }
    }
  }
}

.grid--mpp-hide-prices {
  .product--teaser {
    .product__footer {
      .product__price {
        display: none !important;
        line-height: 30px;
      }
    }
  }
}

.grid--mpp__item,
.collection-quickshop {
  .product__footer {
    .product__size {
      display: none;
    }
  }
}

.prod_inv_status-3 {
  .product__footer {
    .product__add-to-bag {
      &.coming-soon {
        display: none !important;
      }
    }
  }
}

.grid--mpp__item {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        li.temp-out-of-stock {
          padding-top: 0px;
          a.notify_me {
            margin: 0;
          }
          .temp-outof-stock_text {
            margin-top: 42px;
          }
        }
      }
    }
    .prod_inv_status-1 {
      .shade-picker__color-actions {
        .sold-out {
          display: none;
        }
      }
    }
    .prod_inv_status-2 {
      .shade-picker__color-actions {
        .temp-out-of-stock {
          background-color: black;
          color: white;
          display: block;
          font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
          height: 60px;
          text-align: center;
        }
        a {
          display: none !important;
        }
      }
    }
    &.prod_inv_status-3 {
      .product__footer {
        .product__add-to-bag {
          &.coming-soon {
            display: none !important;
          }
        }
      }
    }
  }
}

.prod_inv_status-2 {
  .product__footer {
    .product__inventory-status {
      .temp-out-of-stock {
        display: block;
      }
    }
  }
}

.flag-icon-uk {
  background-image: url('/media/export/cms/flags/4x3/gb.svg');
}

.checkout .recommended-product-items .info .formatted_unit_price {
  position: absolute;
  bottom: 46px;
}

.checkout .recommended-product-items .info .size {
  position: absolute;
  bottom: 30px;
}

label.label.error.picker-label {
  color: #b84947;
}

li.carousel-slide.artist--products__carousel-slide.slick-slide {
  height: 500px !important;
}

li.carousel-slide {
  .product--teaser {
    .product__images {
      bottom: 52px;
    }
    .product__footer {
      padding-bottom: 10px !important;
      .product__price_ppu {
        top: 35px;
      }
    }
  }
  .product__detail {
    height: 574px;
  }
  .shade-picker__trigger {
    bottom: 180px !important;
  }
  .shade-picker {
    bottom: 160px !important;
  }
}

.artist-detail-formatter-v1 li.carousel-slide {
  .product--teaser {
    .product__footer {
      .product__price_ppu {
        top: 49px;
      }
    }
  }
}

.giftcard-container {
  &.gift-card-balance {
    margin: 0 auto;
    .gift-card-balance__photo {
      text-align: center;
      background: black;
      height: auto;
      @media (min-width: 768px) {
        height: 640px;
      }
      img {
        width: auto;
      }
    }
    .gift-card-balance__content {
      padding-top: 1em;
      @include breakpoint($bp--small-down) {
        .giftcard_number_container,
        .giftcard_pin_container {
          float: none;
          width: 98%;
          margin-bottom: 1em;
        }
      }
    }
    .btn {
      min-width: 228px;
      @include breakpoint($bp--small-down) {
        margin-bottom: 1em;
      }
    }
  }
}

.giftcard {
  width: 30%;
  float: left;
  margin: auto 10%;
  padding-top: 15px;
  margin-bottom: 2%;
  .giftcard__detail {
    display: inline-block;
    border-top: 1px solid #c7c7c7;
    width: 75%;
    .giftcard__title {
      font-size: 1.5rem;
    }
    .giftcard__description p {
      text-transform: uppercase;
      margin: 6px 80px 40px 0;
      width: 75%;
    }
    a.btn {
      height: 30px;
      line-height: 31px;
      float: right;
    }
    footer.giftcard__footer {
      padding-top: 15px;
      border-top: 1px solid #c7c7c7;
      margin-top: 1%;
      div.gift-card__buttons {
        display: inline-block;
        float: left;
        width: 100%;
      }
      .gift-card__buttons .terms__condition {
        display: none;
        margin-bottom: 1.2em;
        width: 100%;
        text-align: right;
        margin-top: 5px;
      }
    }
  }
}

.contact-us-page .js-email-customer-service-details {
  padding-top: 120px !important;
}

.prechat-form-container {
  .liveperson-btn-container {
    margin: 10px 0;
    overflow: hidden;
    .chat_link_left_text_container {
      .chat_link_left_text {
        width: 100%;
        font-family: $ano-bold_regular-font;
        font-size: 18px;
        text-align: center;
        padding: 0px 20px;
        height: 50px;
        line-height: 50px;
        color: white;
        cursor: pointer;
        background: black;
        display: inline-block;
      }
      .chat_link_left_text.offline,
      .chat_link_left_text.busy {
        background: #d3d3d3;
      }
    }
  }
  .chat-column-content {
    display: block;
    float: left;
    width: 238px;
    &.right {
      float: right;
    }
    .beauty-advice-btn,
    .order-question-btn {
      width: 100%;
    }
  }
  .chat-hours-msg {
    margin-top: 10px;
  }
  #prechat_header_logo {
    background-color: black;
    .prechat_header_logo_mask {
      width: 115px;
      overflow: hidden;
      #chat-header {
        width: 351px;
        margin-bottom: 0;
      }
    }
  }
}

ul.contact-nav.rm li {
  margin-bottom: 20px;
}

ul.contact-nav.rm .details {
  min-height: 660px;
}

.artistry-video-player__products {
  .product--teaser {
    .product__footer {
      .product__price_ppu {
        display: none !important;
      }
    }
  }
  .product--teaser {
    .product__header {
      .product__price_ppu {
        display: block !important;
      }
    }
  }
}

.site-bag__contents-message {
  .display_non_pro_user {
    text-decoration: underline;
  }
}

[class^='artistry-shop-colors'] {
  .product--teaser {
    .product__footer {
      .product__price_ppu {
        display: none !important;
      }
    }
  }
  .product--teaser {
    .product__header {
      .product__price_ppu {
        display: block !important;
      }
    }
  }
  .product__inventory-status li.product__inv-status-item {
    padding: 6px 0px 40px !important;
    float: right !important;
  }
}

.prod_inv_status-2 .temp-out-of-stock,
.product--shaded.prod_inv_status-3 .product__footer .coming-soon,
.prod_inv_status-5 .inactive {
  display: block;
  .grid--mpp__item & {
    display: none;
  }
  .product__bag-bar & {
    @include swap_direction(margin, $spacing 0 0 0);
  }
}
/* donations */
.donations.panel {
  .donationMessages {
    .single-message {
      color: #f14a29;
    }
  }
  .donation_info_panel {
    width: 100%;
    padding: 2% 0%;
    .donation_logo {
      float: left;
      height: 100%;
      padding: 0px 20px 0px 0px;
    }
    .donation_logo_mobile {
      display: none;
      float: left;
      height: 100%;
    }
    .donation_message {
      font-size: 14px;
    }
    .donation_message_mobile {
      display: none;
      margin: 0% 0% 0% 25%;
      font-size: 14px;
    }
  }
  .donation_amounts_panel {
    h2.donation-panel__title {
      font-size: 14px;
      text-align: right;
      border-bottom: none;
      margin-bottom: 0px;
      background-color: #eff0f0 !important;
      margin: 2% 0%;
      letter-spacing: 0.1em;
      width: 100%;
      padding: 1% 10% 0% 0%;
      #select-amount {
        font-size: 20px;
        padding: 12px 0px 0px 90px;
        float: left;
      }
      #select-amount-mobile {
        display: none;
      }
      .picker {
        display: inline-block;
      }
    }
  }
}
/* Phone Order App */
#csr_header {
  a {
    display: block;
    border-bottom: none;
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 14px;
  }
  #csr_info_box {
    border-right: 1px solid #ff0000;
  }
  #csr_registered_user_info,
  #cust_cart {
    border-right: 1px solid #ff0000;
  }
  .csr_table {
    td {
      vertical-align: middle;
      padding: 0 20px;
    }
    #csr_dragger {
      cursor: move;
      width: 40px;
      padding: 0;
      background: #ff0000;
    }
  }
}

.site-container {
  .artistry--artist,
  .artistry-video-player__products {
    li.product__inv-status-item.sold-out {
      @include breakpoint($bp--large-down) {
        display: inline-block;
      }
    }
  }
}
/* Green border deletion */
.viewcart.panel {
  .donations.panel {
    .messages {
      background-color: #ffffff !important;
      border-bottom: none !important;
    }
  }
}

#confirmation-panel {
  .confirmation-panel__confirm-text--donation a {
    color: #dbdbdb;
  }
}

#samples-panel {
  #samples_popup_view {
    .clearfix.product {
      height: 430px;
      ul.skus {
        li {
          width: 100%;
          float: left;
          position: absolute;
          bottom: 0px;
        }
      }
    }
  }
  .add-button {
    position: relative !important;
    bottom: 0px !important;
    margin-top: 20px;
    float: right;
  }
}

.tabbed-block {
  .mpp-complementary-products {
    @include breakpoint($bp--large-up) {
      max-height: 530px;
    }
  }
}

.site-container {
  .collection-detail-formatter {
    .prod_inv_status-2,
    .prod_inv_status-3 {
      .product__footer {
        a.notify_me {
          width: auto;
          line-height: 30px;
          top: 0px;
          padding: 0px 15px;
        }
      }
    }
  }
}

.checkout__sidebar {
  #viewcart-panel {
    .products {
      .cart-header {
        &.clearfix {
          &.mobile_hidden {
            display: none;
          }
        }
      }
    }
  }
}

.site-header {
  .site-header__tools {
    .site-email-signup {
      padding: 0 !important;
      cursor: pointer;
    }
    .site-email-signup__link {
      line-height: 49px;
      label {
        cursor: pointer;
        display: block;
        height: 11px;
        position: relative;
        top: 15px;
        font-size: 10px;
        font-family: 'roboto-mono_regular', Helvetica, Arial, Sans-Serif;
        line-height: normal;
      }
      i.site-email-signup__link-icon {
        line-height: normal;
        position: relative;
        left: 27px;
        top: 5px;
        height: 17px;
      }
    }
    .block-template-site-my-mac-v1 {
      width: 42%;
    }
    .block-template-site-email-signup-v1 {
      width: 36%;
    }
  }
}

@media (min-width: 967px) {
  .site-header {
    .site-header__tools {
      .site-email-signup {
        padding: 0;
      }
      .site-email-signup__link {
        i.site-email-signup__link-icon {
          left: 35px;
        }
      }
    }
  }
}

@media (min-width: 1281px) {
  .site-header {
    .site-header__tools {
      .site-email-signup__link {
        i.site-email-signup__link-icon {
          left: 27px;
        }
      }
    }
    .site-header__suffix {
      .block-template-site-email-signup-v1 {
        width: 30%;
      }
    }
  }
}

.product__shipping {
  padding-top: 0;
  position: relative;
  top: 65px;
}

.invis-content {
  border-bottom: 1px solid $color--gray--lighter;
  padding-bottom: 20px;
  position: relative;
  top: -50px;
  .invis-start__panel {
    .invis-start__content {
      border-top-width: 0;
      padding-top: 5px;
      text-transform: uppercase;
    }
  }
}

.invis-search__container {
  .invis-result {
    &.no-inventory {
      color: $black;
      a {
        color: $black;
      }
      .invis-result__inv-status {
        color: $color--gray;
      }
    }
    &.call-for-availability {
      .invis-result__inv-status {
        color: $color--gray;
      }
    }
    &.has-available {
      .invis-result__inv-status {
        color: green;
      }
    }
  }
}

.samples-overlay {
  #samples-panel {
    padding: 0 !important;
  }
}

.product__detail {
  .product__inventory-status {
    .sold-out {
      margin-bottom: 26px;
    }
  }
}

.appt-book {
  .confirm {
    .confirm-container {
      .book-appt-container {
        a {
          &.info-icon {
            color: $color--white;
          }
        }
        .password-notice {
          float: right;
        }
      }
    }
  }
}

.search-results {
  .products {
    .pagination {
      padding: 35px 0;
    }
  }
}
