@include breakpoint($bp--small-down) {
  .checkout.panel {
    margin: 0;
  }
}

.viewcart {
  .site-header {
    .site-header__extra {
      #mobile-nav {
        display: block;
        @include breakpoint($bp--largest-up) {
          display: none;
        }
      }
      .header-search__icon {
        display: block;
      }
    }
    .site-header__tools {
      .site-bag__link,
      .site-my-mac__link {
        display: inline-block;
      }
    }
  }
  @include breakpoint($bp--medium) {
    .pc_hidden {
      display: block !important;
    }
  }
}

.checkout {
  select {
    text-decoration: none !important;
  }
  .cp-checkout-cta {
    @include swap_direction(padding-left, 17%);
    background: $color--black
      url('https://static.afterpay.com/integration/product-page/logo-clearpay-white.png') no-repeat
      75% 20px;
    background-size: 118px 21px;
    width: 270px;
    text-align: #{$ldirection};
    @include breakpoint($bp--large-up) {
      @include swap_direction(padding-left, 20px);
      background-position: 86% 20px;
    }
  }
  .picker-radio {
    @include swap_direction(margin, 1.5em 0 1em);
    &.picker {
      &.checked {
        .picker-handle {
          .picker-flag {
            width: 8px;
            height: 8px;
            border: 3px solid #{$color--black};
          }
        }
      }
      .related-media {
        &.cc {
          .card-img {
            margin-#{$ldirection}: 4px;
            width: 34px;
            @include breakpoint($bp--large-up) {
              margin-#{$ldirection}: 6px;
              width: 40px;
            }
          }
        }
      }
    }
    .picker-handle {
      border: 3px solid #{$color--black};
      margin-right: 8px;
      margin-left: 0;
    }
  }
  &__content {
    padding: 0 0 0 1em;

    // hide the viewcart panel header, which has been placed differently within the markup (outside of the content/sidebar columns, as per MAC NA)
    .viewcart-header {
      display: none;
    }
    .panel {
      margin-right: 1em;
      padding: 0;
      border-bottom: 0;
      .btn {
        min-width: 228px; /* bug 254133 */
      }
      @include breakpoint($bp--small-down) {
        .btn {
          min-width: 100%;
        }
        .banner {
          img {
            width: 100%;
          }
        }
      }
    }
    /* on mobile the place order button isnt inside a panel class  */
    #checkout-review-panel {
      .submit {
        @include breakpoint($bp--small-down) {
          .btn {
            min-width: 100%;
          }
        }
      }
    }
    /* add border from panels that are active or collapsed */
    .collapsed,
    .active,
    .finished {
      border-bottom: 1px solid #dbdbdb;
      padding: 7px 0;
    }
    .sign-in-panel {
      margin-right: 1em;
      .messages-container {
        #error_gdpr_return_user_alert_checkout {
          .forgot-pass-gdpr {
            color: $color--black;
            &__notify {
              background-color: $color--white-smoke;
              padding: 20px;
              a.btn {
                color: $color--white;
              }
            }
            &__guest {
              border-top: 1px solid $color--black;
              margin-top: 20px;
              padding-top: 20px;
            }
          }
        }
      }
    }
    .panel.guarantee-panel {
      border-bottom: 0;
    }
  }
  .sign-in-panel.panel.paypal.finished {
    display: none;
  }
  .checkout__content .cart-item .no_sel {
    padding-left: 25px;
  }
  .samples .offer .product-list .product .details .product_name {
    min-height: 50px;
  }
  .checkout__sidebar #samples-panel .product-list {
    max-height: 585px;
  }
  &__sidebar {
    .tab-bar {
      display: none;
    }
    .samples-panel {
      margin-top: -0.7em;
      &__submit-container {
        display: none;
      }
      footer {
        display: none;
      }
      .sample-select-button {
        display: none;
      }
    }
    .viewcart-panel {
      border-top: 1px solid #dbdbdb;
      margin-top: 1em;
      .cart-header {
        display: none;
      }
    }
    .viewcart-header {
      position: relative;
    }
    .item-count {
      right: 5.25em;
      text-transform: uppercase;
      &__edit {
        right: 1em;
        a {
          text-decoration: underline;
        }
      }
      &,
      &__edit {
        position: absolute;
        top: 1.75em;
      }
    }
  }
  &-header {
    // set up some specific rules for the checkout header
    position: relative;
    &__title {
      @include breakpoint($bp--small-down) {
        padding-top: 2.5rem;
      }
    }
    .continue-shopping {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .mobile-breadcrumb {
    display: none;
  }
  .continue-buttons {
    .btn {
      &.disabled {
        cursor: default;
        text-decoration: none;
      }
    }
  }
  #recommended-products-panel {
    border-top: 1px solid #dbdbdb;
    margin-top: 2em;
    .recommended-item {
      .description {
        .info {
          height: 9.5em;
          .formatted_price {
            bottom: 2em;
          }
          .formatted_unit_price {
            bottom: 1em;
          }
        }
      }
    }
  }
}

.sms-promo-wrapper {
  .form-element-prefix {
    display: block;
  }
}

.offer-code-panel {
  margin-bottom: 1em;
  p {
    &.note {
      display: block;
    }
  }
  .btn {
    min-width: 228px;
  }
  .form-submit {
    border: none;
  }
  .apply-link {
    @include swap_direction(padding, 0);
    color: $color--black;
    background-color: inherit;
    border-bottom: 1px solid;
    font-family: $ano-bold_regular-font;
    font-size: 19px;
    height: initial;
    line-height: 2;
  }
  @include breakpoint($bp--small-down) {
    .btn {
      min-width: 100%;
    }
  }
}

#need-help-panel {
  margin-bottom: 1em;
  .link {
    list-style: none;
    a:visited {
      color: inherit;
    }
    @include breakpoint($bp--large-up) {
      a {
        color: $color--white !important;
      }
    }
  }
}

.viewcart-buttons-panel {
  @include breakpoint($bp--small-down) {
    .edit-cart {
      margin: 2em 1em 0em 1em;
    }
    #bottom-viewcart-buttons & {
      .continue-shopping {
        display: none;
      }
      .checkout__sidebar & {
        background-color: $color--white;
      }
    }
  }
  .or {
    display: none;
  }
}

.set-collection-point .form-item div {
  padding-bottom: 10px;
}

.shipping-panel {
  #qas.error {
    border: 1px solid red;
    padding: 10px;
  }
  &.substate-manual {
    #manual-address-fields {
      display: block !important;
      visibility: visible !important;
    }
  }
  #continue-btn {
    clear: both;
  }
  .gift-options__content_no_signup {
    width: 660px;
  }
  @include breakpoint($bp--small-up) {
    .content.column {
      float: left;
      width: 50%;
    }
    .content.panel-right {
      width: 70%;
    }
    .content.panel-left {
      width: 30%;
    }
    #shipping-address-display {
      margin-top: 10px;
    }
    .gift-options__content {
      img {
        margin-bottom: 30px;
      }
    }
  }
}

.card_message_to,
.card_message_from {
  float: left;
  width: 50%;
}

.card_message_from {
  padding-left: 5px;
}

.card_message_to {
  padding-right: 5px;
}

.shipmethod-panel {
  .select2-choice,
  .select2-arrow {
    .checkout__sidebar & {
      background-color: $color--gray--lightest;
    }
  }
}

.registration-panel {
  .form-item {
    &.password-hint {
      display: none;
    }
  }
}

.email-and-sms-promotions {
  .promotions-container {
    h3:first-child {
      margin-top: 4px;
    }
    .email_promotions {
      .form-item {
        label.sms_label,
        label.form-element-prefix {
          display: block;
          float: left;
        }
        label.form-element-prefix {
          margin-left: 5px;
        }
        input {
          clear: both;
        }
      }
    }
  }
}

#confirmation-page {
  .checkout__content {
    .registration-panel {
      margin-left: 0;
      .form-item {
        &.password-hint {
          display: none;
        }
      }
      .registration-panel__title {
        &.checkout__panel-title {
          font-size: 2rem;
        }
      }
      .registration-form-container {
        .password-field {
          width: 50%;
          @include breakpoint($bp--small-down) {
            width: 100%;
          }
          &__info {
            @include breakpoint($bp--small-up) {
              bottom: calc(50% - 50px);
            }
          }
        }
        .form-item {
          &.confirm-email-label {
            font-family: $roboto-mono_bold-font;
            text-transform: initial;
          }
        }
      }
    }
    #sign-in-confirmation-panel {
      header {
        h2 {
          font-size: 2rem;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      .messages-container {
        .single-message {
          padding-bottom: 20px;
        }
      }
      .form-item {
        &.confirm-email-label {
          font-family: $roboto-mono_bold-font;
          text-transform: initial;
          label {
            display: inline-block;
          }
        }
      }
    }
  }
}

.sign-in-panel {
  .img_mail {
    display: none;
  }
}

.gift-cards {
  width: 60%;
}

#review-panel {
  border-bottom: none;
  .sub-section {
    &.address {
      border-bottom: 1px solid $color--gray--lightest;
      margin-bottom: 10px;
      padding-bottom: 20px;
    }
  }
  .form-container {
    &.pay_method {
      @include swap_direction(margin-top, 10px);
      @include swap_direction(padding-top, 10px);
      border-top: 1px solid #{$color--black};
    }
    .form-item {
      position: inherit;
      &.payment-options {
        @include swap_direction(margin, 0);
      }
      &.card-expiry-date {
        float: none;
        .small_select {
          padding: 0;
        }
      }
    }
    .sub-section {
      width: 60%;
    }
    .pc_hidden {
      display: none;
    }
    .adyen-submit {
      display: none;
    }
    .paypal-terms {
      .adyen-submit {
        display: block !important;
        .button--secondary {
          padding: 0 10px;
          @include breakpoint($bp--large-up) {
            padding: 0 30px;
          }
        }
      }
    }
  }
  .payment_form {
    @include clearfix();
  }
  #checkout_review {
    .form-item {
      &.checkbox {
        &.error {
          border: 1px solid red;
          padding: 2px;
        }
      }
    }
  }
  .payment-options {
    @include swap_direction(padding, 10px 0);
    border: none;
    position: relative;
    &.paypal-option {
      @include swap_direction(margin, 0);
      border-top: 1px solid #{$color--black};
      border-bottom: 1px solid #{$color--black};
    }
    &.afterpay-option {
      @include swap_direction(margin, 0 0 15px 0);
      border-bottom: 1px solid #{$color--black};
      @include breakpoint($bp--medium-up) {
        @include swap_direction(padding, 25px 0);
        .picker-radio {
          display: inline;
          @include swap_direction(margin, 0);
        }
      }
      .afterpay {
        font-family: #{$helvetica-font};
        font-size: 15px;
        .afterpay-learnmore {
          span {
            width: 17px;
            height: 17px;
            font-size: 11px;
          }
        }
        @include breakpoint($bp--medium-down) {
          .afterpay-disclaimer {
            display: inline-block;
          }
        }
      }
    }
    .picker-label {
      color: $color--text;
      font-family: $headline-font-family;
      font-size: 18px;
      line-height: 22px;
      @if $fonts_update == true {
        font-family: $headline-ano-bold-font;
      }
      .afterpay {
        font-size: 12px;
        font-family: $roboto-mono_regular-font;
        text-transform: none;
        display: block;
        @if $fonts_update == true {
          font-family: $helvetica-font;
          line-height: 15px;
        }
        @include breakpoint($bp--medium-up) {
          display: inline-block;
        }
      }
      .afterpay-image {
        @include swap_direction(padding, 0 5px 0 8px);
        width: 115px;
      }
      .purchase-agreement {
        white-space: nowrap;
      }
      .paypal-image {
        @include swap_direction(margin-left, 8px);
        height: auto;
        width: auto;
        @include breakpoint($bp--medium-up) {
          height: 27px;
        }
      }
    }
    .default-payment {
      display: none;
    }
  }
}

.klarna-account {
  align-items: center;
  display: inline-flex;
  font-size: 15px;
  gap: 4px;
  line-height: 1.5;
  text-transform: none;
  &:empty {
    display: none;
  }
  &--cart {
    font-family: $helvetica-font;
    margin: 0 0 15px;
    .klarna-account__learn-more {
      height: 17px;
      min-width: 17px;
      width: 17px;
    }
  }
  &--payment {
    font-size: 12px;
    .klarna-account__prepend,
    .klarna-account__append,
    .klarna-account__logo {
      display: none;
    }
  }
  &--over-limit {
    .klarna-account__prepend {
      display: none;
    }
  }
  &__logo {
    background-image: url('#{$base-theme-path}svg-icons/src/icon--klarna.svg');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 30px;
    min-width: 55px;
    text-indent: -999em;
    width: 55px;
  }
  &__learn-more {
    align-items: center;
    background-color: $color--black;
    border: 0;
    border-radius: 50%;
    display: flex;
    height: 14px;
    justify-content: center;
    min-width: 14px;
    text-indent: -999rem;
    width: 14px;
    &::after {
      color: $color--white;
      content: '\0069';
      font-family: $times-font;
      font-size: 11px;
      text-indent: -1px;
      text-transform: lowercase;
    }
  }
}

.payment-container {
  border-top: 1px solid $color--black;
  .payment-option {
    border-bottom: 1px solid $color--black;
    display: block;
    margin: 0;
    #test-badge-element-id {
      display: none !important;
    }
    .klarna-img {
      height: 30px;
      margin: -5px 6px 0;
      width: 55px;
    }
    .adyen-payment-method {
      .adyen-payment-type {
        border: 0;
        position: absolute;
      }
    }
    .adyen-parent-label {
      & > .adyen-label-content {
        .adyen-label-text {
          display: block;
          margin-bottom: 4px;
          @include breakpoint($bp--medium-up) {
            display: inline-block;
            margin-bottom: 0;
            margin-#{$rdirection}: 6px;
          }
        }
        .card-img {
          margin: -3px 6px 0 0;
          width: 40px;
        }
      }
    }
    .adyen-label-scheme {
      margin-top: 10px;
      max-width: 420px;
      &:not(.adyen-label-scheme--single) {
        cursor: pointer;
        display: none;
        padding-#{$ldirection}: 18px;
        position: relative;
        &::before,
        &::after {
          border-radius: 50%;
          content: '';
          position: absolute;
        }
        &::before {
          border: 2px solid $color--black;
          height: 8px;
          #{$ldirection}: 0;
          top: 4px;
          width: 8px;
        }
        &::after {
          background-color: $color--black;
          display: none;
          height: 4px;
          #{$ldirection}: 4px;
          top: 8px;
          width: 4px;
        }
      }
      &--single {
        .adyen-label-content {
          display: none;
        }
      }
    }
    .adyen-label-content {
      .card-img {
        margin-#{$rdirection}: 6px;
        width: 30px;
      }
    }
    .adyen-checkout {
      &__input {
        border-color: $color--black;
        border-radius: 0;
        height: 45px;
        padding-bottom: 11px;
        padding-top: 11px;
        width: 100%;
      }
      &__label {
        max-width: 100%;
        width: 100%;
      }
      &__button {
        background-color: $color--black;
        border-radius: 0;
        height: 45px;
        width: auto;
        &:hover,
        &:focus {
          background-color: $color--black;
          box-shadow: none;
        }
        &--standalone {
          border-radius: 0;
          height: 45px;
          margin-top: 15px;
        }
      }
    }
    .payment_options-terms,
    .adyen-payment,
    .adyen-scheme {
      display: none;
    }
    .adyen-scheme {
      .adyen-component {
        margin: 10px 0;
      }
      .adyen-payment-type {
        height: 10px;
        margin: 15px 0 0;
        width: 10px;
        &:checked + {
          .adyen-label-scheme {
            &:not(.adyen-label-scheme--single) {
              &::after {
                display: block;
              }
            }
          }
        }
      }
    }
    .picker-radio {
      margin: 0;
      padding: 1.2em 0 1em;
      &.checked {
        .payment_options-terms {
          color: $color--black;
          display: block;
          font-size: 12px;
          line-height: 1.4;
          .afterpay-button {
            background: $color--black
              url(/media/export/cms/checkout/clearpay-logo-white-transparent.svg) no-repeat 65% 47%;
            color: $color--white;
            font-size: 12px;
            font-weight: bold;
            height: 44px;
            letter-spacing: 1.4px;
            line-height: 1.4;
            padding: 0 11%;
            text-align: $ldirection;
            width: 308px;
            @include breakpoint($bp--large-up) {
              background-position: 75% 47%;
              padding: 0 6%;
            }
          }
          .adyen-submit {
            clear: $ldirection;
          }
          .clearpay-button {
            display: block;
            clear: $ldirection;
          }
        }
        .paypal-terms {
          .payment-terms {
            @include breakpoint($bp--large-up) {
              width: 70%;
            }
          }
        }
        .adyen-payment,
        .adyen-scheme {
          display: block;
        }
        .payment-terms {
          float: $ldirection;
          margin-bottom: 0;
          margin-top: 10px;
          width: 100%;
          text-transform: none;
          @include breakpoint($bp--large-up) {
            width: 50%;
          }
          a {
            border: none;
            text-transform: none;
          }
          .overlay-wrapper {
            display: inline-block;
            text-transform: uppercase;
            .overlay-link-cms,
            .close-link {
              border-bottom: 1px solid $color--black;
              text-transform: none;
            }
          }
        }
        .adyen-parent-label {
          .payment-terms {
            float: none;
            @include breakpoint($bp--large-up) {
              max-width: 420px;
              width: 100%;
            }
          }
        }
      }
      .picker-label {
        color: $color--black;
        font-size: 18px;
        .related-media {
          @include swap_direction(margin, 0 0 0 8px);
        }
        .after_pay-installment {
          display: inline-block;
          font-size: 12px;
          text-transform: none;
        }
      }
    }
  }
}

.payment-loading {
  background: url(/media/images/common/ajax-loading.gif) no-repeat center center;
  opacity: 0.5;
  pointer-events: none;
  > .loading {
    display: none;
  }
}

#adyen-payment {
  .component-container {
    .adyen-checkout__label {
      display: block !important; // third party override style
    }
  }
}
.chckt-sdk {
  .chckt-pm {
    border: none;
  }
  .chckt-pm__details {
    background: $color--white !important; // third party override style
    padding-#{$ldirection}: 0;
    .chckt-input-field--recurring {
      background: $color--white;
      border: 1px solid $color--gray--black;
      border-radius: 0;
      height: 40px;
      padding: 12px 10px;
      @include breakpoint($bp--large-up) {
        height: 45px;
        padding: 8px 10px;
      }
    }
  }
  .chckt-form--max-width {
    color: $color--black;
    min-width: 345px;
    max-width: 472px;
    .chckt-form-label__text {
      color: $color--black;
      &--dark {
        padding: 0 30px;
        #{$rdirection}: 9px;
      }
    }
  }
  .chckt-form-label--exp-date {
    min-width: 132px;
    @include breakpoint($bp--large-up) {
      min-width: 182px;
    }
  }
  .chckt-form-label--cvc {
    min-width: 178px;
    padding-#{$ldirection}: 22px;
    @include breakpoint($bp--large-up) {
      padding-#{$ldirection}: 0;
    }
  }
  .chckt-input-field--cvc {
    max-width: 72%;
    @include breakpoint($bp--large-up) {
      max-width: 75%;
    }
  }
  .chckt-form-label__error-text {
    color: $color--red;
    text-transform: none;
  }
  .chckt-input-field {
    border-radius: 0;
    border-color: $color--gray--black;
    color: $color--black;
    height: 40px;
    @include breakpoint($bp--large-up) {
      height: 45px;
    }
  }
  .chckt-input-field--error {
    color: $color--red;
    border: 2px solid $color--red;
  }
  .chckt-pm__header {
    background: $color--white !important; // third party override style
    padding-#{$ldirection}: 0;
    .chckt-pm__image {
      margin: 0;
      #{$rdirection}: 2px;
    }
    .chckt-pm__name {
      color: $color--black;
    }
  }
  .chckt-button-container {
    width: 100%;
    @include breakpoint($bp--large-up) {
      min-width: 400px;
      width: 51%;
    }
    .chckt-button.chckt-button--disabled {
      opacity: 0.5;
    }
    .chckt-more-pm-button {
      background: $color--white;
      border: none;
      padding-#{$ldirection}: 0;
      &__icon {
        display: none;
      }
      &__text {
        color: $color--black;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-#{$ldirection}: 0;
        text-decoration: underline;
      }
    }
    .chckt-button {
      background: $color--black;
      margin-top: 0;
      padding: 10px;
    }
  }
}

.checkout__content {
  .checkout__subtitle {
    font-family: #{$helvetica-font};
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0;
  }
  #msg-panel {
    background: $color--black;
    margin-top: 15px;
    padding: 10px 10px 0;
    .panel-text {
      color: $color--white;
      line-height: 1.5em;
    }
  }
}

.paypal-loading {
  background-size: 23px;
  background-position: top;
  display: inline-block;
  height: 30px;
  margin-#{$rdirection}: 10px;
  width: 30px;
}
.paypal-process {
  opacity: 0.2;
}
.paypal-overlay {
  background: $color--black;
  #{$ldirection}: 0;
  height: 100%;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.paypal-processing {
  #{$ldirection}: 0;
  margin: 0 auto;
  padding: 10px;
  position: fixed;
  #{$rdirection}: 0;
  text-align: center;
  top: 25%;
  width: 100%;
  z-index: 999;
  .process-content {
    background: $color--white;
    max-width: 335px;
    margin: 0 auto;
    position: relative;
    padding: 30px 10px;
    z-index: 999;
  }
  .paypal-logo {
    padding-top: 20px;
    width: 90px;
  }
  .mac-logo {
    padding-bottom: 25px;
  }
}

#adyen-payment-form {
  input[type='checkbox'] {
    & ~ span {
      &::before {
        background: $color--white;
        border: 2px solid $color--black;
        border-radius: 0;
        content: ' ';
        cursor: pointer;
        display: inline-block;
        height: 16px;
        position: absolute;
        top: 1px;
        width: 16px;
        #{$ldirection}: 0;
      }
    }
  }
  input[type='checkbox'] {
    &:checked {
      & ~ span {
        &::after {
          background: $color--white;
          border-color: $color--black;
          border-style: solid;
          border-width: 0 2px 2px 0;
          border-radius: 0;
          content: ' ';
          cursor: pointer;
          display: block;
          height: 8px;
          position: absolute;
          transform: rotate(45deg);
          top: 4px;
          width: 4px;
          -webkit-transform: rotate(45deg);
          #{$ldirection}: 6px;
        }
      }
    }
  }
}

#cboxClose {
  font-size: 1em;
}

@include breakpoint($bp--small-up) {
  #review-panel {
    .form-item.card-type,
    .form-item.card-number {
      width: 60%;
    }
    .form-item.card-cvn {
      input {
        margin-right: 2em;
        width: 12em;
      }
    }
    .address.two-column-form {
      clear: both;
    }
  }
}

.small_select {
  width: 10em;
}

.card-expiry-date {
  span.float-left {
    margin: 0 20px;
    padding-top: 20px;
  }
}

#cart .select2-container {
  border: 0;
}

.paypal-checkout-modal {
  background: $color--white;
  color: $color--black;
}

#review-panel {
  .address-to-use {
    .address-to-use {
      float: left;
      margin-right: 20px;
    }
    .address-to-use:last-of-type {
      margin-right: 0;
    }
  }
  .place-order {
    text-decoration: none;
    border: none;
  }
  .select-address {
    .delivery-address {
      clear: both;
    }
  }
}

.checkout__content .form-item .select2-container .select2-choice {
  text-decoration: none;
}

#bottom-viewcart-buttons .viewcart-buttons-panel .continue-buttons {
  width: 100%;
  padding-right: 94px;
  margin-top: 1em;
  margin-bottom: 1em;
}

#bottom-viewcart-buttons {
  .continue-buttons {
    .applepay-button-container {
      text-align: left;
      padding-left: 155px;
      .apple-pay-button {
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        -webkit-appearance: -apple-pay-button;
        -apple-pay-button-style: black;
      }
      .apple-pay-button.button-style-buy {
        -apple-pay-button-type: buy;
      }
      .apple-pay-button.button-style-set-up {
        -apple-pay-button-type: set-up;
      }
    }
    @media (min-width: 40em) and (max-width: 641px) {
      span.applepay-button-container {
        display: block;
        float: left;
        padding-left: 0;
      }
    }
    @media (max-width: 40em) {
      .applepay-button-container {
        display: block;
        min-width: 100%;
        padding-left: 0;
        .btn {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #bottom-viewcart-buttons .viewcart-buttons-panel .continue-buttons {
    width: 100%;
    padding-right: 0px;
  }
  #checkout_billing_panel,
  .gift-cards {
    width: 100%;
  }
  .card_message_to,
  .card_message_from {
    float: none;
    width: 100%;
    padding: 0px;
  }
}

.checkout__sidebar .select2-choice,
.checkout__sidebar .select2-choice .select2-arrow {
  background-color: #f0f0f0;
}

#samples_sidebar_view {
  display: none;
}

#samples_popup_view {
  .remaining {
    background-color: white !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  display: inline;
  .product__desc {
    border-top: 1px solid #dbdbdb;
  }
  .sample_p_hdr {
    float: left;
    max-width: 13em;
    text-align: left;
    min-height: 5em;
    @include breakpoint($bp--small-down) {
      max-width: 25em;
    }
    h4 {
      padding: 1em 0 0 0;
      line-height: 1;
      margin: 0;
    }
  }
  .product-size {
    padding: 1.5em 0 0 0;
    float: right;
    width: 2em;
  }
  .product-img {
    @include breakpoint($bp--small-down) {
      text-align: center;
    }
  }
  .sample-select-button-popup {
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    min-width: 1em !important;
    clear: none;
    float: none;
    padding: 0 15px;
    &:hover {
      text-decoration: none;
      border-bottom: 2px solid black;
    }
    @include breakpoint($bp--small-down) {
      font-size: 1.5rem;
      display: block;
      width: 100%;
      height: 60px;
      line-height: 64px;
      margin-bottom: 1em;
    }
  }
  .skus {
    li {
      padding: 1.2em 0em 0em 0em;
      min-width: 1em;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      min-height: 5em;
      @include breakpoint($bp--small-down) {
        border: none;
        padding: 0;
      }
    }
  }
}

.checkout__sidebar {
  #samples_sidebar_view {
    display: inline;
  }
  #samples_popup_view {
    display: none;
  }
}

.checkout__sidebar .tab-switcher {
  margin-top: 19px;
}

.samples {
  &-page {
    .product {
      &-name {
        font-size: 1.5rem;
      }
    }
  }
  &-page &-panel {
    &__submit-container {
      display: none;
    }
  }
}

.field-container--grid {
  &.address-to-use {
    .form-item {
      @include breakpoint($bp--medium-up) {
        @include layout($grid--3, 1em) {
          &:nth-of-type(3n + 1) {
            @include grid-span(1, 1);
            clear: both;
            .valid_marker,
            .invalid_marker {
              right: 18px;
            }
          }
          &:nth-of-type(3n + 2) {
            @include grid-span(1, 2);
          }
          &:nth-of-type(3n + 3) {
            @include grid-span(1, 3);
          }
        }
      }
    }
  }
}

#guarantee-panel,
.offer-code-panel {
  padding: 1em;
  border-bottom: 1px solid #dbdbdb;
  margin-top: 1em;
}

.site-container {
  .checkout-header {
    margin-left: 1em;
    @include breakpoint($bp--small-down) {
      margin-right: 1em;
    }
    .checkout-header__title {
      font-size: 30px;
    }
  }
  .viewcart {
    .checkout__content {
      .viewcart-panel {
        &__content {
          .cart-item__qty {
            .sample-qty {
              margin-top: 5px;
            }
          }
        }
        .cart-items products {
          .cart-item__qty {
            select {
              padding: 0;
              option {
                text-align: left;
              }
            }
          }
        }
        .checkout__panel-content {
          .products {
            .sample {
              .qty {
                padding-top: 18px;
              }
            }
          }
        }
      }
    }
    .checkout-header {
      .viewcart-buttons-panel {
        display: none;
      }
    }
    @include breakpoint($bp--large-down) {
      #samples-panel {
        display: none;
      }
    }
  }
}

.samples-panel {
  .remaining {
    background-color: #f0f0f0;
    padding: 0 1.2em 1em 1em;
    margin-bottom: 1em;
  }
  @include breakpoint($bp--small-down) {
    padding: 0 15px 0;
  }
}

.samples-overlay {
  .samples-panel {
    padding: 0 0 1em 0;
    header {
      h2 {
        margin-top: 0;
      }
      padding-left: 27px;
    }
    .remaining {
      background-color: white;
    }
    .available_popup {
      padding-left: 27px;
    }
    ul.product-list {
      padding-left: 27px;
    }
    #checkout_samples {
      .product-list {
        .product {
          float: left;
          margin-right: 4%;
          text-align: center;
          width: 29%;
          &__thumb,
          &__desc {
            float: none;
            margin-right: auto;
            width: auto;
          }
          &__desc {
            .skus {
              .product-size {
                padding: 0 0 2px 0;
                text-align: left;
              }
              .sample-select-button {
                min-width: 100%;
                &.selected {
                  background-color: #fff;
                  color: #000;
                  border-color: #000;
                  border-width: 2px;
                }
              }
            }
          }
        }
      }
      .btn {
        min-width: 228px;
      }
      .samples-panel__submit-container {
        position: absolute;
        bottom: 20px;
        width: 96%;
        .btn.form-submit {
          float: right;
        }
      }
    }
  }
}

body.checkout-confirm {
  #toolbar {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    filter: progid:DXImageTransform.Microsoft.Shadow(enabled=false);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Shadow(enabled=false)';
    vertical-align: top;
  }
  #confirmation-page {
    max-width: inherit;
    .checkout__content {
      .confirmation-page__my-shades {
        width: 9.5em;
        padding-right: 0;
      }
      .checkout__content__inner {
        width: 65%;
      }
      @include breakpoint($bp--medium-down) {
        .checkout__content__inner {
          width: 100%;
        }
      }
    }
  }
  #confirmation-panel {
    background-color: $color--gray--black;
    color: $color--white;
    padding: 0;
    text-align: center;
    header {
      padding: 0 10px;
      .header__section {
        border-bottom: 1px solid #373737;
        padding: 30px 0;
        h3,
        h4,
        p {
          margin: 0;
          font-size: 2.5rem;
        }
        h4 {
          font-size: 1.5rem;
        }
        p {
          color: #ccc;
          font-size: 1rem;
          margin: 0 auto;
          max-width: 50%;
          text-transform: uppercase;
        }
        .actions {
          margin-top: 1.5rem;
          a {
            border-bottom-color: #ccc;
            color: #ccc;
          }
        }
      }
    }
  }
  .checkout__content {
    &__inner {
      margin-left: 130px;
      header {
        .checkout__panel-title {
          line-height: 0.9;
        }
        .checkout__subtitle {
          margin-bottom: 1.5rem;
        }
      }
      .how--we-will-use-container {
        ul {
          list-style-type: disc;
          margin: 0 0 2.5rem 15px;
        }
      }
      .promotions-container {
        .checkout__subtitle {
          display: none;
          visibility: hidden;
        }
        .email_promotions {
          p,
          & > a {
            display: none;
            visibility: hidden;
          }
        }
      }
    }
    @include breakpoint($bp--medium-down) {
      &__inner {
        margin-left: 0;
      }
    }
  }
}
/* Reduced Header on Checkout Pages - ukprojectb-3881 */
body.index,
body.samples,
body.wp_samples {
  .site-navigation,
  .site-header__suffix,
  .header-search {
    display: none;
  }
  .site-header {
    &__prefix {
      width: 50%;
      float: $ldirection;
      @include swap_direction(margin, 0 -100% 0 25%);
      clear: none;
      .site-logo {
        .site-logo__image {
          @include swap_direction(margin, 0 auto !important);
        }
        @include breakpoint($bp--xxlarge-up) {
          .site-logo__image {
            @include swap_direction(margin, 0 auto !important);
          }
        }
      }
    }
    &__menu {
      width: 25%;
      margin-#{$ldirection}: 0;
      .site-header__extra {
        float: $ldirection;
        .mobile-checkout-back {
          display: block;
          cursor: pointer;
        }
      }
    }
  }
}
/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  &.photo-tip {
    padding-top: 5px;
  }
  a {
    text-decoration: none;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    color: #555;
    border: 1px solid #999999;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext-under {
    top: 125%;
  }
  .tooltiptext-over {
    bottom: 125%;
  }
  .tooltiptext-under::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }
  .tooltiptext-over::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  &:hover .tooltiptext-under,
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
}
/* Info popup styles for nav privacy policy */
/* Inherit EMEA style as they made base theme changes */

.info-link {
  cursor: pointer;
  margin-bottom: 10px;
  border: 0px;
  display: block;
}

.font-clr {
  color: #fff;
}

.info-icon {
  cursor: pointer;
  background: black;
  color: white;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: block;
  text-transform: lowercase;
  padding: 1px 0px 0px 6px;
}

.info-message-txt {
  display: none;
}

#info-message-box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  background-color: #fff;
  padding: 20px;
  width: 90%;
  border: 1px solid #000;
  max-width: 500px;
  &:before {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: 0px;
    border: 10px solid transparent;
    border-bottom-color: #000;
    top: -10px;
    margin-top: -10px;
  }
  &.top-right-arrow:before {
    left: 478px;
  }
  #info-text {
    position: relative;
  }
  #info-close {
    position: absolute;
    top: -20px;
    right: -20px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

.read-more-active {
  .text-less {
    display: inline;
  }
  .text-more {
    display: none;
  }
}

.read-more-copy {
  display: inline;
}

.text-less {
  display: none;
}

//emphasis colors
.information {
  color: #{$color--information--message};
}

.success {
  color: #{$color--success--message};
}

.warning {
  color: #{$color--warning--message};
}

.error {
  color: #{$color--error--message};
}

.checkout__content {
  .messages-container {
    .single-message,
    &.error a {
      color: #{$color--error--message};
    }
  }
}

.order-summary__content {
  .instalments {
    text-transform: none;
  }
  .clearpay {
    font-family: #{$helvetica-font};
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: inherit;
    text-transform: inherit;
    clear: both;
    width: 100%;
    .checkout__sidebar & {
      width: 100%;
      img {
        width: 80px !important;
      }
      .afterpay-learnmore {
        span {
          width: 14px;
          height: 14px;
          font-size: 10px;
        }
      }
    }
    img {
      width: 115px !important;
    }
    .afterpay-learnmore {
      span {
        width: 17px;
        height: 17px;
        font-size: 11px;
      }
    }
  }
}
// Specificity required to override existing styles.
.checkout__content {
  #order-summary-panel {
    .order-summary__content {
      .instalments {
        width: 100%;
        @media (min-width: $width-medium) {
          width: 75%;
        }
      }
    }
  }
}

.select2-drop {
  .select2-results {
    li {
      line-height: 1.4;
    }
  }
}
