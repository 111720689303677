.tout-single-editorial {
  margin: 0 auto;
  overflow: hidden;
  max-width: 1281px;
  &__image-container {
    padding-bottom: 1.5em;
  }
  &__text-container {
    display: flex;
  }
  &__text-container-side {
    padding: 0 1.7em 0 0;
  }
  &__eyebrow,
  &__headline,
  &__subtitle {
    margin-top: 0;
  }
}

@media (min-width: 769px) {
  .tout-single-editorial--float-right,
  .tout-single-editorial--float-left {
    display: flex;
    align-items: center;
    .tout-single-editorial {
      &__image-container {
        flex: 1 1 50%;
      }
      &__text-container {
        flex: 1 1 50%;
      }
    }
  }
  .tout-single-editorial--float-right {
    .tout-single-editorial {
      &__image-container {
        order: 3;
      }
    }
  }
}
